import React from "react";
import {
  Select,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  ListItemAvatar,
  Avatar,
  Grid,
  styled,
  MenuItem,
} from "@mui/material";
// Setting Type
import ChannelImage from "../../../assets/images/icons/filter_icons/icon_settingtype_Channel.png";
import ProngSetImage from "../../../assets/images/icons/filter_icons/icon_settingtype_Prong.png";
import FlushSetImage from "../../../assets/images/icons/filter_icons/icon_settingtype_Flush.png";
import BeadPaveSetImage from "../../../assets/images/icons/filter_icons/icon_settingtype_BeadPave.png";
import BarSetImage from "../../../assets/images/icons/filter_icons/icon_settingtype_Bar.png";
// Band Style
import FlatImage from "../../../assets/images/icons/filter_icons/icon_bandstyle_Flat.png";
import HalfRoundImage from "../../../assets/images/icons/filter_icons/icon_bandstyle_HalfRound.png";
import TaperedImage from "../../../assets/images/icons/filter_icons/icon_bandstyle_Tapered.png";
import EdgedImage from "../../../assets/images/icons/filter_icons/icon_bandstyle_Edged.png";
// Band Type
import EternityImage from "../../../assets/images/icons/filter_icons/icon_bandtype_Eternity.png";
import PartwayImage from "../../../assets/images/icons/filter_icons/icon_bandtype_Partway.png";
import NoStonesImage from "../../../assets/images/icons/filter_icons/icon_bandtype_NoStones.png";
// Stone Shape
import RoundImage from "../../../assets/images/icons/filter_icons/icon-StoneShapeRound.png";
import PrincessImage from "../../../assets/images/icons/filter_icons/icon_stoneshape_Princess.png";
// Category
import AllBandsImage from "../../../assets/images/icons/filter_icons/icon_cat_AllBands.png";
import BandswithStonesImage from "../../../assets/images/icons/filter_icons/icon_cat_BandsWithStones.png";
import BandswithoutStonesImage from "../../../assets/images/icons/filter_icons/icon_cat_BandsWithoutStones.png";
import MensBandsImage from "../../../assets/images/icons/filter_icons/icon_cat_MensBands.png";
import DiamondAnniversaryBandsImage from "../../../assets/images/icons/filter_icons/icon_cat_DiamondAnniversaryBands.png";
import DiamondWeddingBandsImage from "../../../assets/images/icons/filter_icons/icon_cat_DiamondWeddingBands.png";
import WedddingBandsImage from "../../../assets/images/icons/filter_icons/icon_cat_WeddingBands.png";
import WomensBandsImage from "../../../assets/images/icons/filter_icons/icon_cat_WomensBands.png";
import StackableBandsImage from "../../../assets/images/icons/filter_icons/icon_cat_StackableBands.png";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  justifyContent: "center",
  textAlign: "left",
  paddingTop: 0,
  paddingBottom: 0,
  margin: theme.spacing(0.5),
  padding: "4px",
}));

const imageMap = {
  ChannelImage,
  ProngSetImage,
  BarSetImage,
  FlushSetImage,
  BeadPaveSetImage,
  FlatImage,
  HalfRoundImage,
  TaperedImage,
  EdgedImage,
  EternityImage,
  PartwayImage,
  RoundImage,
  PrincessImage,
  BandswithStonesImage,
  BandswithoutStonesImage,
  MensBandsImage,
  DiamondAnniversaryBandsImage,
  DiamondWeddingBandsImage,
  WedddingBandsImage,
  WomensBandsImage,
  AllBandsImage,
  NoStonesImage,
  StackableBandsImage,
};

const MultiSelectField = ({
  name,
  label,
  value,
  onChange,
  options,
  imageUrl,
  category,
}) => {
  return (
    <Grid item xs={6} md={5} lg={3} xl={1.5} sx={{ marginBottom: "1rem" }}>
      <FormControl fullWidth>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          name={name}
          labelId={`${name}-label`}
          id={`${name}-select`}
          multiple={label !== "Category"}
          value={value}
          onChange={onChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              return options
                .filter((option) => selected.includes(option.value))
                .map((option) => option.label)
                .join(", ");
            } else {
              const selectedOption = options.find(
                (option) => option.value === selected
              );
              return selectedOption ? selectedOption.label : "";
            }
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.4)",
                maxHeight: "330px",
                maxWidth: "100%",
                overflow: "auto",
              },
            },
          }}
        >
          {options.map((option) => (
            <StyledMenuItem key={option.value} value={option.value}>
              {category === "All Bands" ? (
                <ListItemAvatar>
                  <Avatar
                    alt={option.label}
                    src={imageMap?.[option.image] || imageUrl}
                    variant="square"
                    sx={{
                      width: 50,
                      height: 50,
                    }}
                  />
                </ListItemAvatar>
              ) : null}
              <ListItemText primary={option.label} />
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

export default MultiSelectField;
