// Third-party libraries
import React, { useContext } from "react";
import { Link as DomLink } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AddShoppingCartSharpIcon from "@mui/icons-material/AddShoppingCartSharp";
import VideocamIcon from "@mui/icons-material/Videocam";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Checkbox,
  Link as MuiLink,
  CardMedia,
  styled,
  Box,
} from "@mui/material";

// Local modules
import { DiamondSearchContext } from "../../utils/stateHandlers/contexts";
import { Default, Mobile } from "../../utils/deviceHelpers";
import formatting from "../../utils/formatHelpers";
import { functions } from "../../utils";
import { storage } from "../../utils";
import api from "../../utils/api";
import MediaPanel from "./controls/MediaPanel";
import placeholder from "../../../src/assets/images/DiaImageComingSoon.jpg";
import {
  CanadaIcon,
  ConflictFreeIcon,
  ConflictFreeReversedIcon,
  RecycleIcon,
  USFlagIcon,
  SpaceIcon,
  LabGrownIcon,
  MinedIcon,
  ASIcon,
  SCSIcon,
} from "../Icons";

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  height: "100%",
  width: "100%",
  objectFit: "cover",
  [theme.breakpoints.down(800)]: {
    height: "350px",
  },
  [theme.breakpoints.between(800, 2000)]: {
    height: "300px",
  },
  [theme.breakpoints.between(2000, 2100)]: {
    height: "400px",
  },
  [theme.breakpoints.up(2100)]: {
    height: "400px",
  },
}));

export default function CertDiamondTable(props) {
  const { diamondState, dispatch } = useContext(DiamondSearchContext);
  const {
    currentTab,
    openPanel,
    tableHeaders,
    fancyColorHeaders,
    mobileTableHeaders,
    fancyColorMobileHeaders,
    sidePanelTableHeaders,
    fcSidePanelHeaders,
    diamonds,
    recentlyViewed,
    selected,
    selectedDiamond,
    searchQuery,
    showFancyColorFilter,
  } = diamondState;
  const loggedIn = storage.getStorageItem("authToken");
  const rows =
    currentTab === "Results"
      ? diamonds
      : currentTab === "RecentlyViewed"
      ? recentlyViewed
      : selected;

  const dataFormat = (format, diamondField) => {
    let formattedData;
    switch (format) {
      case "price2":
        formattedData = "$" + parseFloat(diamondField).toFixed(2);
        break;
      case "price0":
        formattedData = "$" + parseFloat(diamondField).toFixed(0);
        break;
      case "num2":
        formattedData = parseFloat(diamondField).toFixed(2);
        break;
      case "percent2":
        formattedData = parseFloat(diamondField).toFixed(2) + "%";
        break;
      case "percent1":
        formattedData = parseFloat(diamondField).toFixed(1) + "%";
        break;
      case "percent0":
        formattedData = parseFloat(diamondField).toFixed(0) + "%";
        break;
      case "images":
        formattedData = "<img src='" + diamondField + "'/>";
        break;
      default:
        formattedData = formatting[format]
          ? formatting[format](diamondField)
          : diamondField;
        break;
    }
    return <>{formattedData}</>;
  };

  const formatCurrency = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };

  const type = (minedOrLab) => (
    <>{minedOrLab === "L" ? <LabGrownIcon /> : <MinedIcon />}</>
  );

  const source = (diamondSource, id, asGrown, scsGrower) => {
    if (diamondSource === "Harmony Recycled") {
      return <RecycleIcon />;
    }

    if (diamondSource === "Conflict Free") {
      return selectedDiamond.HooverStockId === id ? (
        <ConflictFreeReversedIcon />
      ) : (
        <ConflictFreeIcon />
      );
    }

    if (diamondSource === "Harmony Canadian") {
      return <CanadaIcon />;
    }

    if (diamondSource === "Harmony US Lab Grown") {
      return (
        <>
          <USFlagIcon />
          &nbsp;
          {asGrown === "Y" && <ASIcon />}
        </>
      );
    }

    if (asGrown === "Y") {
      return <ASIcon />;
    }

    if (scsGrower === "Y") {
      return <SCSIcon />;
    }
    return <></>;
  };

  const diamondShape = (video, link, id, shape, index) => (
    <Stack direction="row" justifyContent="center" alignItems="center">
      {video === "Y" ? (
        link.length > 0 ? (
          <VideocamIcon
            className="media-icon"
            sx={{ verticalAlign: "middle" }}
          />
        ) : (
          <CameraAltIcon
            className="media-icon"
            sx={{ verticalAlign: "middle" }}
          />
        )
      ) : (
        <SpaceIcon />
      )}
      {functions.getDiamondShape(shape)}
    </Stack>
  );

  const hidePreview = function () {
    dispatch({ type: "setOpenPanel", payload: false });
    dispatch({ type: "setSelectedDiamond", payload: {} });
  };

  function updateSearchQuery(header) {
    const sortField = searchQuery.sort.field;
    const sortDirection = searchQuery.sort.direction;
    if (sortField === header && sortDirection === "asc") {
      dispatch({
        type: "updateSort",
        payload: {
          sortField: header,
          sortDirection: "desc",
        },
      });
    } else if (sortField === header && sortDirection === "desc") {
      dispatch({
        type: "updateSort",
        payload: {
          sortField: header,
          sortDirection: "asc",
        },
      });
    } else if (sortField !== header) {
      dispatch({
        type: "updateSort",
        payload: {
          sortField: header,
          sortDirection: "asc",
        },
      });
    }
  }

  function selectDiamond(diamondId, diamondChecked) {
    const diamondToSelect = diamonds.find((d) => {
      return diamondId === d.HooverStockId;
    });
    if (diamondChecked === false) {
      diamondToSelect.Checked = true;
      diamondToSelect.conditionalSale = false;
      dispatch({
        type: "setSelected",
        payload: [
          ...selected.filter(
            (d) => d.HooverStockId !== diamondToSelect.HooverStockId
          ),
          diamondToSelect,
        ],
      });
      dispatch({
        type: "setCartPreview",
        payload: [
          ...selected.filter(
            (d) => d.HooverStockId !== diamondToSelect.HooverStockId
          ),
          diamondToSelect,
        ],
      });
    } else {
      diamondToSelect.Checked = false;
      dispatch({
        type: "setSelected",
        payload: selected.filter((d) => d.HooverStockId !== diamondId),
      });
      dispatch({
        type: "setCartPreview",
        payload: selected.filter((d) => d.HooverStockId !== diamondId),
      });
    }
  }

  function viewDiamond(event, diamond) {
    dispatch({
      type: "setOpenPanel",
      payload: true,
    });
    const url = `diamonds/detail/`;
    api.fetch(url, diamond.HooverStockId).then((res) => {
      dispatch({ type: "setSelectedDiamond", payload: res.data });
    });
    const viewedArray = [
      ...recentlyViewed.filter(
        (d) => d.HooverStockId !== diamond.HooverStockId
      ),
      diamond,
    ];
    dispatch({ type: "setRecentlyViewed", payload: viewedArray });
  }

  function openInNewTab(url) {
    window.open(url, "_blank").focus();
  }

  function clickToShowPreview(e, diamond) {
    if (e.target.classList.contains("checkbox-for-diamond-row")) {
      return;
    }
    if (
      e.currentTarget.id !== selectedDiamond.HooverStockId &&
      e.target.parentElement.classList.contains("normal-table-row")
    ) {
      viewDiamond(e, diamond);
    } else if (
      e.currentTarget.id === selectedDiamond.HooverStockId &&
      e.target.parentElement.classList.contains("selected-table-row")
    ) {
      const url =
        window.location.protocol +
        "//" +
        window.location.host +
        "/diamond/" +
        e.currentTarget.id;
      openInNewTab(url);
    }
  }

  function mobile(e, diamond) {
    const url =
      window.location.protocol +
      "//" +
      window.location.host +
      "/diamond/" +
      e.currentTarget.id;
    const viewedArray = [
      ...recentlyViewed.filter(
        (d) => d.HooverStockId !== diamond.HooverStockId
      ),
      diamond,
    ];
    dispatch({ type: "setRecentlyViewed", payload: viewedArray });
    openInNewTab(url);
  }

  function addSingleDiamondToCartPreview(diamond) {
    diamond.conditionalSale = false;
    dispatch({
      type: "setCartPreviewSingle",
      payload: diamond,
    });
  }

  let minedOrLab =
    selectedDiamond.MinedOrLab === "L" ? "Lab-Grown Diamond" : "Mined Diamond";

  const DiamondHeaders = (headers) => (
    <TableHead>
      <TableRow>
        <Default>
          <TableCell padding="checkbox">
            <Typography variant="headerFont">Select</Typography>
          </TableCell>
        </Default>

        {headers.map((header) => (
          <TableCell
            key={header.results_order}
            align="center"
            className="pointer"
            onClick={() => updateSearchQuery(header.name)}
          >
            <Typography variant="headerFont">{header.label}</Typography>
            {searchQuery.sort.field === header.name && (
              <>
                {searchQuery.sort.direction === "asc" ? (
                  <ArrowDropUpIcon sx={{ verticalAlign: "middle" }} />
                ) : (
                  <ArrowDropDownIcon sx={{ verticalAlign: "middle" }} />
                )}
              </>
            )}
          </TableCell>
        ))}
        <Default>
          <TableCell padding="checkbox"></TableCell>
        </Default>
      </TableRow>
    </TableHead>
  );

  const DiamondGrids = () => (
    <Grid container spacing={2} marginTop={"3rem"}>
      {rows.map((diamond) => (
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <Card
            sx={{ width: "100%", minHeight: "100%", boxShadow: 4 }}
            elevation={12}
          >
            <StyledCardMedia
              image={diamond.ImageLink ? diamond.ImageLink : placeholder}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <IconButton
                  disabled={!loggedIn}
                  onClick={() =>
                    selectDiamond(diamond.HooverStockId, diamond.Checked)
                  }
                >
                  <FavoriteIcon
                    className="checkbox-for-diamond-row"
                    color={diamond.Checked ? "secondary" : "primary"}
                    sx={{
                      fill: diamond.Checked ? "red" : "none",
                      stroke: diamond.Checked ? "red" : "currentColor",
                      strokeWidth: 3,
                    }}
                  />
                </IconButton>

                <DomLink
                  to={{
                    pathname: `/diamond/${diamond.HooverStockId}`,
                  }}
                  target="_blank"
                >
                  <Tooltip title="View Details" placement="top-start">
                    <IconButton
                      component="span"
                      key={diamond.HooverStockId + "view"}
                      id={diamond.HooverStockId}
                      className={
                        "pointer actions-icon " +
                        (selectedDiamond.HooverStockId === diamond.HooverStockId
                          ? " actions-icon--selected"
                          : "")
                      }
                      to={{
                        pathname: `/diamond/${selectedDiamond.HooverStockId}`,
                      }}
                      target="_blank"
                    >
                      <ZoomInIcon
                        sx={{ verticalAlign: "middle", fontSize: "3rem" }}
                      />
                    </IconButton>
                  </Tooltip>
                </DomLink>
              </Box>
            </StyledCardMedia>

            <CardContent sx={{ minHeight: "120px" }}>
              <Stack spacing={0.5} sx={{ marginBottom: "2rem" }}>
                <Typography variant="h6">
                  {diamond.Carat} {diamond.Shape}{" "}
                  {showFancyColorFilter ? diamond.FancyColorIntensity : null}{" "}
                  {showFancyColorFilter ? diamond.FancyColor : diamond.Color}{" "}
                  {diamond.Clarity}/{diamond.HooverCertification}
                </Typography>
                <Typography sx={{ color: "#9e9e9e" }}>
                  {diamond.Polish}
                  {diamond.Symmetry ? `/${diamond.Symmetry}` : ""}
                  {diamond.Fluorescence ? `/${diamond.Fluorescence}` : "/None"}
                </Typography>
                {diamond.FancyColorOvertone && (
                  <Typography sx={{ color: "#9e9e9e" }}>
                    Fancy Color Overtone: {diamond.FancyColorOvertone}
                  </Typography>
                )}
              </Stack>
            </CardContent>
            <CardActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid container>
                {props.loggedIn ? (
                  <React.Fragment>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "8px",
                        }}
                      >
                        <Typography variant="subtitle2">
                          {formatCurrency(diamond.CalcPricePerCarat)} p/c
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "8px",
                        }}
                      >
                        <Typography variant="h3">
                          {formatCurrency(diamond.HooverPrice)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          alignItem: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Tooltip title="Add to Cart" placement="top-start">
                          <IconButton
                            component="span"
                            className={
                              "pointer actions-icon " +
                              (selectedDiamond.HooverStockId ===
                              diamond.HooverStockId
                                ? " actions-icon--selected"
                                : "")
                            }
                            onClick={(e) => {
                              dispatch({
                                type: "setOpenCartPreviewSingle",
                                payload: true,
                              });
                              addSingleDiamondToCartPreview(diamond);
                            }}
                          >
                            <AddShoppingCartSharpIcon
                              sx={{ verticalAlign: "middle", fontSize: "3rem" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
  const DiamondRows = (headers) => (
    <TableBody>
      {rows.map((diamond) => (
        <React.Fragment key={diamond.HooverStockId}>
          <Mobile>
            <TableRow
              id={diamond.HooverStockId}
              className={
                selectedDiamond.HooverStockId === diamond.HooverStockId
                  ? "selected-table-row"
                  : "normal-table-row"
              }
              onClick={(e) => mobile(e, diamond)}
            >
              {headers.map((header, index) => (
                <TableCell align="center" padding="none" key={header.name}>
                  <Typography
                    variant="cellFont"
                    sx={
                      selectedDiamond.HooverStockId === diamond.HooverStockId
                        ? { color: "white" }
                        : {}
                    }
                  >
                    {header.name === "Shape"
                      ? diamondShape(
                          diamond.ImageVideoAvailable,
                          diamond.VideoLink,
                          diamond.HooverStockId,
                          diamond.Shape,
                          index
                        )
                      : header.name === "MinedOrLab"
                      ? type(diamond.MinedOrLab)
                      : header.name === "DiamondSource"
                      ? source(
                          diamond.DiamondSource,
                          diamond.HooverStockId,
                          diamond.AsGrown,
                          diamond.ScsGrower
                        )
                      : header.name === "HooverPrice" && !props.loggedIn
                      ? "-"
                      : header.name === "CalcPricePerCarat" && !props.loggedIn
                      ? "-"
                      : dataFormat(header.format, diamond[header.name])}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </Mobile>
          <Default>
            <TableRow
              id={diamond.HooverStockId}
              key={diamond.HooverStockId}
              className={
                selectedDiamond.HooverStockId === diamond.HooverStockId
                  ? "selected-table-row"
                  : "normal-table-row"
              }
              onClick={(e) => clickToShowPreview(e, diamond)}
            >
              <TableCell align="center" padding="checkbox">
                <Checkbox
                  className="checkbox-for-diamond-row"
                  disabled={!loggedIn}
                  color={
                    selectedDiamond.HooverStockId === diamond.HooverStockId
                      ? "secondary"
                      : "primary"
                  }
                  checked={diamond.Checked}
                  onChange={() =>
                    selectDiamond(diamond.HooverStockId, diamond.Checked)
                  }
                />
              </TableCell>
              {headers.map((header, index) => (
                <TableCell align="center" padding="none" key={header.name}>
                  <Typography
                    variant="cellFont"
                    sx={
                      selectedDiamond.HooverStockId === diamond.HooverStockId
                        ? { color: "white" }
                        : {}
                    }
                  >
                    {header.name === "Shape"
                      ? diamondShape(
                          diamond.ImageVideoAvailable,
                          diamond.VideoLink,
                          diamond.HooverStockId,
                          diamond.Shape,
                          index
                        )
                      : header.name === "MinedOrLab"
                      ? type(diamond.MinedOrLab)
                      : header.name === "DiamondSource"
                      ? source(
                          diamond.DiamondSource,
                          diamond.HooverStockId,
                          diamond.AsGrown,
                          diamond.ScsGrower
                        )
                      : header.name === "HooverPrice" && !props.loggedIn
                      ? "-"
                      : header.name === "CalcPricePerCarat" && !props.loggedIn
                      ? "-"
                      : dataFormat(header.format, diamond[header.name])}
                  </Typography>
                </TableCell>
              ))}
              {props.loggedIn && (
                <TableCell align="center" padding="none">
                  <Stack direction="row" alignItems="center">
                    {/* {diamond.ShipsSameDay === "Y" && (
                      <Tooltip title="Ships Same Day" placement="top-start">
                        <LocalShippingTwoToneIcon
                          color="error"
                          sx={{
                            verticalAlign: "middle",
                            fontSize: "2rem",
                            marginRight: "1rem",
                          }}
                        />
                      </Tooltip>
                    )} */}
                    <Tooltip title="Add to Cart" placement="top-start">
                      <IconButton
                        component="span"
                        className={
                          "pointer actions-icon " +
                          (selectedDiamond.HooverStockId ===
                          diamond.HooverStockId
                            ? " actions-icon--selected"
                            : "")
                        }
                        onClick={(e) => {
                          dispatch({
                            type: "setOpenCartPreviewSingle",
                            payload: true,
                          });
                          addSingleDiamondToCartPreview(diamond);
                        }}
                      >
                        <AddShoppingCartSharpIcon
                          sx={{ verticalAlign: "middle", fontSize: "2rem" }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View Details" placement="top-start">
                      <IconButton
                        component="span"
                        key={diamond.HooverStockId + "view"}
                        id={diamond.HooverStockId}
                        className={
                          "pointer actions-icon " +
                          (selectedDiamond.HooverStockId ===
                          diamond.HooverStockId
                            ? " actions-icon--selected"
                            : "")
                        }
                        onClick={(e) => viewDiamond(e, diamond)}
                      >
                        <ZoomInIcon
                          sx={{ verticalAlign: "middle", fontSize: "2rem" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </TableCell>
              )}
            </TableRow>
          </Default>
        </React.Fragment>
      ))}
    </TableBody>
  );

  if (currentTab === "Results" && diamonds.length === 0) {
    return (
      <Typography variant="h6" sx={{ margin: "4rem" }}>
        No diamonds matched your search criteria.
      </Typography>
    );
  } else if (currentTab === "RecentlyViewed" && recentlyViewed.length === 0) {
    return (
      <Typography variant="h6" sx={{ margin: "4rem" }}>
        No recently viewed diamonds found.
      </Typography>
    );
  } else if (currentTab === "Selected" && selected.length === 0) {
    return (
      <Typography variant="h6" sx={{ margin: "4rem" }}>
        No diamonds are currently selected.
      </Typography>
    );
  }

  if (tableHeaders) {
    return (
      <>
        <Mobile>
          <Table size="small" className="results-table">
            {props.view !== "list" ? (
              DiamondGrids(
                !openPanel && !showFancyColorFilter
                  ? tableHeaders
                  : !openPanel && showFancyColorFilter
                  ? fancyColorHeaders
                  : openPanel && showFancyColorFilter
                  ? fcSidePanelHeaders
                  : sidePanelTableHeaders
              )
            ) : showFancyColorFilter ? (
              <>
                {DiamondHeaders(fancyColorMobileHeaders)}
                {DiamondRows(fancyColorMobileHeaders)}
              </>
            ) : (
              <>
                {DiamondHeaders(mobileTableHeaders)}
                {DiamondRows(mobileTableHeaders)}
              </>
            )}
          </Table>
        </Mobile>
        <Default>
          <Stack direction="row">
            <Table size="small" className="results-table">
              {props.view === "list" ? (
                <>
                  {DiamondHeaders(
                    !openPanel && !showFancyColorFilter
                      ? tableHeaders
                      : !openPanel && showFancyColorFilter
                      ? fancyColorHeaders
                      : openPanel && showFancyColorFilter
                      ? fcSidePanelHeaders
                      : sidePanelTableHeaders
                  )}
                  {DiamondRows(
                    !openPanel && !showFancyColorFilter
                      ? tableHeaders
                      : !openPanel && showFancyColorFilter
                      ? fancyColorHeaders
                      : openPanel && showFancyColorFilter
                      ? fcSidePanelHeaders
                      : sidePanelTableHeaders
                  )}
                </>
              ) : (
                DiamondGrids(
                  !openPanel && !showFancyColorFilter
                    ? tableHeaders
                    : !openPanel && showFancyColorFilter
                    ? fancyColorHeaders
                    : openPanel && showFancyColorFilter
                    ? fcSidePanelHeaders
                    : sidePanelTableHeaders
                )
              )}
            </Table>

            {openPanel && selectedDiamond && (
              <Card
                sx={{
                  minWidth: 500,
                  borderRadius: "0px",
                  marginTop: "-1px",
                  maxHeight: "78rem",
                }}
                id="diamondpreview"
              >
                <CardContent>
                  <div className="close-preview-panel" onClick={hidePreview}>
                    <CloseIcon sx={{ verticalAlign: "middle" }} />
                  </div>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={6}>
                      <Typography variant="body3">
                        SKU: {selectedDiamond.HooverStockId}
                      </Typography>
                      {selectedDiamond.Vendor === "124555" &&
                        selectedDiamond.MinedOrLab === "L" && (
                          <Typography variant="subtitle1">
                            Ships Same Day <LocalShippingTwoToneIcon />
                          </Typography>
                        )}
                    </Stack>
                    {selectedDiamond !== null && (
                      <MediaPanel
                        diamond={selectedDiamond}
                        context="side_panel"
                        context_class="side_panel"
                      />
                    )}
                    <Grid container rowSpacing={1}>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="body3">{minedOrLab}</Typography>
                          <Typography variant="body3">
                            {selectedDiamond.Carat}ct {selectedDiamond.Shape}{" "}
                            {selectedDiamond.Color} {selectedDiamond.Clarity}
                          </Typography>
                          {showFancyColorFilter && (
                            <Typography variant="body3">
                              Color: {selectedDiamond.FancyColor}{" "}
                              {`- ${selectedDiamond.FancyColorIntensity}`}
                            </Typography>
                          )}
                          {showFancyColorFilter &&
                            selectedDiamond.FancyColorOvertone && (
                              <Typography variant="body3">
                                Overtone: {selectedDiamond.FancyColorOvertone}{" "}
                              </Typography>
                            )}
                          <Typography variant="body3">
                            {selectedDiamond.Measurements}
                          </Typography>
                          <Typography variant="body3">
                            Polish: {selectedDiamond.Polish}
                          </Typography>
                          <Typography variant="body3">
                            Symmetry: {selectedDiamond.Symmetry}
                          </Typography>
                          <Typography variant="body3">
                            Cut: {selectedDiamond.Cut}
                          </Typography>
                          <Typography variant="body3">
                            Fluor.: {selectedDiamond.Fluorescence}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <Typography variant="body3">
                            Table:{" "}
                            {Number(selectedDiamond.Table).toFixed(0) + "%"}
                          </Typography>
                          <Typography variant="body3">
                            Depth:{" "}
                            {Number(selectedDiamond.Depth).toFixed(0) + "%"}
                          </Typography>
                          <Typography variant="body3">
                            L/W:{" "}
                            {selectedDiamond.Measurement1 &&
                              selectedDiamond.Measurement2 &&
                              (
                                selectedDiamond.Measurement1.replace("mm", "") /
                                selectedDiamond.Measurement2.replace("mm", "")
                              ).toFixed(2)}
                          </Typography>
                          {!showFancyColorFilter && (
                            <Typography className="nowrap" variant="body3">
                              % off Rap:{" "}
                              {Number(
                                selectedDiamond.CalcPercentOffRap
                              ).toFixed(1) + "%"}
                            </Typography>
                          )}
                          <Typography variant="body3">
                            Report: {selectedDiamond.Certification}{" "}
                            {selectedDiamond.CertificationLocation > "" && (
                              <MuiLink
                                href={selectedDiamond.CertificationLocation}
                                target="_blank"
                                className="underline"
                              >
                                View
                              </MuiLink>
                            )}
                          </Typography>
                          {props.loggedIn && (
                            <>
                              <Typography variant="body3">
                                Price P/C:{" "}
                                {formatCurrency(
                                  selectedDiamond.CalcPricePerCarat
                                )}
                              </Typography>
                              <Typography variant="body3">
                                Total Price:{" "}
                                {formatCurrency(selectedDiamond.HooverPrice)}
                              </Typography>
                            </>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </CardContent>
                <CardActions>
                  <Stack className="button-panel">
                    <DomLink
                      to={{
                        pathname: `/diamond/${selectedDiamond.HooverStockId}`,
                      }}
                      target="_blank"
                      style={{ width: "100%" }}
                    >
                      <Button variant="contained" className="button-panel">
                        View Diamond
                      </Button>
                    </DomLink>
                    <Button
                      variant="contained"
                      color="success"
                      className="button-panel"
                      disabled={props.loggedIn ? false : true}
                      onClick={() => {
                        dispatch({
                          type: "setOpenCartPreviewSingle",
                          payload: true,
                        });
                        addSingleDiamondToCartPreview(selectedDiamond);
                      }}
                    >
                      Add to Cart
                    </Button>
                  </Stack>
                </CardActions>
              </Card>
            )}
          </Stack>
        </Default>
      </>
    );
  }
}
