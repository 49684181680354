// Third-party libraries
import React, { useState, useEffect } from "react";
//import axios from "axios";
import { Box, Stack } from "@mui/material";
// Local modules
import { Diamond360Icon } from "../../Icons";
import Shape from "../shapes/Shape";
import DiamondIconStrip from "./DiamondIconStrip";
import placeholder from "../../../assets/images/DiaImageComingSoon.jpg";
export default function MediaPanel(props) {
  const MEDIA_VIEW_TOP_DIAGRAM = "TopDiagram";
  const MEDIA_VIEW_PROFILE_DIAGRAM = "ProfileDiagram";
  const MEDIA_VIEW_VIDEO360 = "Video360";
  const MEDIA_VIEW_TOP_IMAGE = "TopImage";

  let EmbedURL = props.diamond.VideoLink;

  const showVideoOption = EmbedURL ? true : false;
  const [showImageOption, setShowImageOption] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (props.diamond.ImageLink) {
      setShowImageOption(true);
    } else {
      setShowImageOption(false);
    }
    setImageError(false);
  }, [props.diamond]);

  const INITIAL_VIEW =
    (showVideoOption && MEDIA_VIEW_VIDEO360) ||
    (showImageOption && MEDIA_VIEW_TOP_IMAGE) ||
    MEDIA_VIEW_TOP_DIAGRAM;

  const [mediaView, setMediaView] = useState(INITIAL_VIEW);

  const mediaClass =
    props.context_class || (props.popover && "add-to-cart-popover") || "";

  useEffect(() => {
    setMediaView(INITIAL_VIEW);
    // const checkUrlStatus = async () => {
    //   try {
    //     const response = await axios.head(EmbedURL);

    //     if (response.status === 200) {
    //       setValidVideo(true);
    //     } else {
    //       setValidVideo(false);
    //     }
    //   } catch (error) {
    //     console.error(
    //       `${EmbedURL} could not be reached. Error: ${error.message}`
    //     );
    //   }
    // };

    // checkUrlStatus();
  }, [props.diamond.HooverStockId, INITIAL_VIEW]);

  return (
    <>
      {mediaView === MEDIA_VIEW_VIDEO360 && (
        <iframe
          src={EmbedURL}
          title="diamond_360"
          width="100%"
          height="350rem"
          style={{ border: "none", display: "auto", textAlign: "center" }}
        />
        // ) : (
        //   mediaView === MEDIA_VIEW_VIDEO360 && (
        //     <img
        //       src={placeholder}
        //       alt="Placeholder"
        //       style={{
        //         width: "100%",
        //         height: "100%",
        //       }}
        //     />
        //   )
        // )
      )}
      {mediaView === MEDIA_VIEW_TOP_IMAGE && !imageError && (
        <img
          src={props.diamond.ImageLink}
          alt="Diamond Top View"
          className={"diamond-image-diagram " + mediaClass}
          onError={() => {
            setImageError(true);
          }}
        />
      )}
      {mediaView === MEDIA_VIEW_TOP_IMAGE && imageError && (
        <img alt="placeholder" src={placeholder} width="100%" />
      )}
      {mediaView === MEDIA_VIEW_TOP_DIAGRAM && (
        <span className="diamond-image-diagram">
          <Shape
            type={props.diamond.Shape}
            color="silver"
            show_diagram={true}
            diamond={props.diamond}
            strokeWidthFactor="0.007"
            stroke_width="0.3"
            width="350"
            height="350"
          />
        </span>
      )}
      {mediaView === MEDIA_VIEW_PROFILE_DIAGRAM && (
        <span className="diamond-image-diagram">
          <Shape
            type={props.diamond.Shape + "Profile"}
            color="silver"
            strokeWidthFactor="0.01"
            diamond={props.diamond}
            show_diagram={true}
            width="350"
            height="350"
          />
        </span>
      )}
      <Box
        sx={
          !props.details
            ? {
                display: "grid",
                gridTemplateColumns: {
                  sm: "repeat(1, 1fr)",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(2, 1fr)",
                  xl: "repeat(2, 1fr)",
                },
              }
            : {}
        }
      >
        <Stack direction="row" spacing={2} justifyContent="center">
          {showVideoOption && (
            <div
              onClick={() => setMediaView(MEDIA_VIEW_VIDEO360)}
              className="pointer thumbnail"
            >
              <Diamond360Icon size="regular" />
            </div>
          )}
          {showImageOption && (
            <div
              onClick={() => setMediaView(MEDIA_VIEW_TOP_IMAGE)}
              className="pointer thumbnail"
            >
              {!imageError && (
                <img
                  src={props.diamond.ImageLink}
                  width="48px"
                  height="48px"
                  alt="Diamond Top View"
                  onError={() => {
                    setImageError(true);
                  }}
                />
              )}
              {imageError && <span>Image</span>}
            </div>
          )}
          <div
            onClick={() => setMediaView(MEDIA_VIEW_TOP_DIAGRAM)}
            className="pointer thumbnail"
          >
            <Shape
              type={props.diamond.Shape}
              color="darkgrey"
              width="46"
              height="46"
              show_diagram={false}
              diamond={props.diamond}
              strokeWidthFactor="0.01"
            />
          </div>
          <div
            onClick={() => setMediaView(MEDIA_VIEW_PROFILE_DIAGRAM)}
            className="pointer thumbnail"
          >
            <Shape
              type={props.diamond.Shape + "Profile"}
              color="darkgrey"
              width="46"
              height="46"
              strokeWidthFactor="0.01"
              show_diagram={false}
              diamond={props.diamond}
            />
          </div>
        </Stack>
        {!props.details && (
          <DiamondIconStrip diamond={props.diamond} show_text={true} />
        )}
      </Box>
    </>
  );
}
