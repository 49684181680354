// Third-party libraries
import React, { useContext, useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import {
  Chip,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

// Local modules
import GAButton from "../../utils/GAComponents/GAButton";
import GALinkButton from "../../utils/GAComponents/GALinkButton";
import formatting from "../../utils/formatHelpers";
import { CartContext } from "../../utils/stateHandlers/contexts";
import { deleteLineItem, subtotal } from "./util";
import PopularSelections from "./PopularSelections";
import placeHolder from "../../../src/assets/images/placeHolder.png";
import api from "../../utils/api";

export default function CartModal(props) {
  const { cartState, cartDispatch } = useContext(CartContext);
  const { cart } = cartState;
  const [popularLinks, setPopularLinks] = useState();
  const removeBreak = (spec) => spec.replace(/<br\s*\/?>/gi, " ");
  const handleClose = () => {
    props.close(false);
  };

  function showProduct(lineItem) {
    if (lineItem.material === "D") {
      if (lineItem.finger_size) {
        return lineItem.finger_size;
      }
      return "Diamond";
    }
    if (lineItem.material === "LG") {
      return "Lab Grown Diamond";
    } else {
      return lineItem.product + lineItem.dimension;
    }
  }
  const productList = (lineItem) => (
    <React.Fragment key={lineItem.id}>
      {lineItem.invoices.map((invoice, index) => (
        <Container maxWidth="md" key={index}>
          <Grid container spacing={2} marginTop="1rem">
            <Grid item xs={3}>
              <div
                style={{
                  border: "1px solid lightgray",
                  display: "flex",
                }}
              >
                <img
                  src={lineItem.image}
                  alt="product"
                  width="100%"
                  height="100%"
                  onError={(e) => {
                    e.target.src = placeHolder;
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={9}>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                marginBottom={"2rem"}
              >
                <Typography>
                  <b>Product</b>: {showProduct(lineItem)}
                </Typography>
                <Typography className="float-right">
                  {formatting["price2"](invoice.total)}
                </Typography>
              </Stack>
              <Stack direction={"row"} marginBottom={"2rem"}>
                <Typography marginRight={"4rem"}>
                  <b>Specification</b>:{" "}
                  {`${invoice.material} - ${removeBreak(
                    invoice.specification
                  )}`}
                </Typography>
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography>
                  <b>Quantity</b>:
                  {lineItem.quantity_unit === "CT"
                    ? invoice.quantity
                    : lineItem.quantity + lineItem.quantity_unit}
                </Typography>
                <Tooltip title="Delete">
                  <Chip
                    className="float-right"
                    label={
                      <DeleteTwoToneIcon sx={{ verticalAlign: "middle" }} />
                    }
                    color="error"
                    sx={{ zIndex: 10 }}
                    variant="outlined"
                    onClick={() => deleteLineItem(lineItem, cartDispatch)}
                  />
                </Tooltip>
              </Stack>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Divider style={{ marginBottom: "3rem" }} />
        </Container>
      ))}
    </React.Fragment>
  );

  useEffect(() => {
    api.fetchStrapi(`/cart-modal-popular-product`).then((response) => {
      setPopularLinks(response.data.data.attributes.links);
    });
  }, []);

  return (
    <>
      <Drawer
        anchor="right"
        open={props.open}
        onClose={handleClose}
        style={{ zIndex: 1500 }}
      >
        <Grid container maxWidth={500} alignItems="center">
          <Grid className="close-btn" item xs={12}>
            <IconButton onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </Grid>

          {cart.length ? (
            <>
              <Grid
                item
                xs={12}
                sx={{ position: "sticky", top: "0px", zIndex: 100 }}
              >
                <Paper
                  sx={{
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h3" textAlign="center" marginTop="2rem">
                    Your Cart
                  </Typography>
                  <Divider sx={{ margin: 0 }} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                {cart.map((lineItem, index) => productList(lineItem, index))}
              </Grid>
              <Grid item xs={12} className="sticky-bottom-center">
                <Paper className="paper-component">
                  <Stack
                    sx={{
                      width: "100%",
                      textAlign: "right",
                      marginBottom: "4rem",
                    }}
                    spacing={2}
                  >
                    <Typography variant="h3" className="cart-price">
                      Subtotal: {formatting["price2"](subtotal(cart))}
                    </Typography>
                    <Stack direction="column" alignItems="center">
                      <GALinkButton
                        pathTo="/cart"
                        color="primary"
                        variant="contained"
                        buttonText="Go To Cart"
                        gaEventName="go_to_cart_button"
                      />
                      <GAButton
                        loadingButton={false}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClickFunction={handleClose}
                        buttonText="Continue Shopping"
                        gaEventName="continue_shopping_button"
                      />
                    </Stack>
                  </Stack>
                </Paper>
              </Grid>
            </>
          ) : (
            <PopularSelections popularLinks={popularLinks} />
          )}
        </Grid>
      </Drawer>
    </>
  );
}
