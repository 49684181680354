import React, { useState, useEffect, useContext, useRef } from "react";
import {
  FormControl,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { ProductLandingContext } from "../../../utils/stateHandlers/contexts";
import KYellowGoldImage from "../../../../src/assets/images/icons/filter_icons/icon_YellowMetal.png";
import KWhiteGoldImage from "../../../../src/assets/images/icons/filter_icons/icon_WhiteMetal.png";
import KPinkGoldImage from "../../../../src/assets/images/icons/filter_icons/icon_PinkMetal.png";
const imageMap = {
  KYellowGoldImage,
  KWhiteGoldImage,
  KPinkGoldImage,
  KPallWhiteGoldImage: KWhiteGoldImage,
  KRedGoldImage: KPinkGoldImage,
  KRoyalYellGoldImage: KYellowGoldImage,
  HarmonyFairminedKYellowGoldImage: KYellowGoldImage,
  HarmonyFairminedKRoyalYellowGoldImage: KYellowGoldImage,
  KRoySterlSilImage: KWhiteGoldImage,
  KPallwhtSterSilImage: KWhiteGoldImage,
  KRoyKPallwhtSterSilImage: KWhiteGoldImage,
  KRedKPallwhtSterSilImage: KPinkGoldImage,
  KRoyKRedSterSilImage: KPinkGoldImage,
  PalladiumImage: KWhiteGoldImage,
  PlatinumRutheniumImage: KWhiteGoldImage,
  SterlingSilverImage: KWhiteGoldImage,
  TruSilverImage: KWhiteGoldImage,
};
function ListSelectField({ name, label, updateFilters, options, imageUrl }) {
  const { landingState } = useContext(ProductLandingContext);
  const { searchQuery, clearAll } = landingState;
  const listSelectField = searchQuery.fields.find(
    (field) => field.name === name
  );
  const defaultSelectedValues = listSelectField?.values || [];
  const [selectedValues, setSelectedValues] = useState(defaultSelectedValues);
  const hasMounted = useRef(false);
  const handleCheckboxChange = (value) => {
    setSelectedValues((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
    updateFilters(name, selectedValues);
  };

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    updateFilters(name, selectedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    if (clearAll) {
      setSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAll]);

  return (
    <>
      <FormControl fullWidth>
        <Typography variant="h6" fontSize="12px" textTransform={"uppercase"}>
          {label}
        </Typography>
        <List
          sx={{
            maxHeight: "260px",
            overflow: "auto",
            border: "1px solid lightgray",
            borderRadius: "10px",
          }}
        >
          {options.map((option) => {
            return (
              <ListItem
                key={option.label}
                value={option.value}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleCheckboxChange(option.value)}
                    checked={selectedValues.includes(option.value)}
                  />
                }
              >
                <ListItemAvatar>
                  <Avatar
                    alt={option.label}
                    src={imageMap?.[option.image] || imageUrl}
                    variant="square"
                    sx={{ width: 30, height: 30 }}
                  />
                </ListItemAvatar>
                <ListItemText primary={option.label} />
              </ListItem>
            );
          })}
        </List>
      </FormControl>
    </>
  );
}

export default ListSelectField;
