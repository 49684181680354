export function processShipDates(items) {
  return items.map((item) => {
    const itemType = isMeleeOrDiamondItem(item);
    return itemType ? updateShipDate(item, itemType) : item;
  });
}

// Helper function to get the next business day
function getNextBusinessDay(dateStr, daysToAdd) {
  let date = new Date(dateStr);
  let businessDaysToAdd = daysToAdd;

  while (businessDaysToAdd > 0) {
    date.setDate(date.getDate() + 1);
    if (date.getDay() !== 6 && date.getDay() !== 0) {
      // Skip weekends
      businessDaysToAdd--;
    }
  }

  return date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
}

// Check if a date is a business day (Monday to Friday)
function isBusinessDay(dateStr) {
  const date = new Date(dateStr);
  const dayOfWeek = date.getDay();
  return dayOfWeek !== 0 && dayOfWeek !== 6; // Not Sunday (0) or Saturday (6)
}

// Check if the item is "melee" or "diamond"
function isMeleeOrDiamondItem(item) {
  return item.finger_size !== ""
    ? "diamond"
    : item.finger_size === ""
    ? "melee"
    : null;
}

// Convert a date to a specific timezone
function convertToTimeZone(date, timeZone) {
  const options = {
    timeZone,
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = formatter.format(date);
  const [hours, minutes, seconds] = formattedDate.split(":");

  const newDate = new Date(date);
  newDate.setHours(hours, minutes, seconds);
  return newDate;
}

// Update the ship date based on the item type and cutoff time
function updateShipDate(response, itemType) {
  const now = new Date();
  const deliveryCutOffTime = new Date();
  deliveryCutOffTime.setHours(16, 0, 0, 0);

  const estTimeZone = "America/New_York";
  const estDeliveryCutOffTime = convertToTimeZone(
    deliveryCutOffTime,
    estTimeZone
  );

  let updatedShipDate = response.ship_info.ship_date;
  if (now > estDeliveryCutOffTime || !isBusinessDay(updatedShipDate)) {
    // Add 2 business days for melee items and 1 for diamond items after cutoff or non-business day
    updatedShipDate = getNextBusinessDay(updatedShipDate, 1);
  }

  response.ship_info.ship_date = updatedShipDate;
  return response;
}
