// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useReducer, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import {
  Container,
  Backdrop,
  CircularProgress,
  Grid,
  Stack,
  Divider,
} from "@mui/material";

// Local modules
import { storage, functions, constants } from "../../../utils";
import api from "../../../utils/api";
import {
  AppContext,
  ProductsContext,
} from "../../../utils/stateHandlers/contexts";
import { productReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import GALinkButton from "../../../utils/GAComponents/GALinkButton";
import WasherImage from "../../../assets/images/WAS_S_XLG.png";
import CircleImage from "../../../assets/images/CIR_S_XLG.png";
import RingBlanks1 from "../../../assets/images/WRB_S_XLG.png";
import RingBlanks2 from "../../../assets/images/WRB_DIAGRAM_XLG.png";
import ProductGalleryViewer from "../components/ProductGalleryViewer";
import DetailTable from "../components/DetailTable";
import Price from "../components/Price";
import ProductSeries from "../components/ProductSeries";
import TechnicalArticles from "../components/TechnicalArticles";
import CustomerTestimonials from "../components/CustomerTestimonials";
import SpecialInstructions from "../components/SpecialInstructions";
import TreatmentField from "../components/TreatmentField";
import filterAds from "../../../utils/helperFunction";
import ProductTitle from "./styles/ProductTitle";
import getProductCodeStrapiId from "./const/Contants";
import RenderField from "./styles/ProductFields";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";
import StrapiChart from "./StrapiChart";
import RelatedItems from "./RelatedItems";
import RecentlyView from "./RecentlyView";
import MetalColumns from "./MetalColumns";
import CompatibleProduct from "./CompatibleProduct";

export default function ProductDetailPage(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const { authToken } = useContext(AppContext);
  const [productState, productDispatch] = useReducer(
    productReducer,
    states.initialProductState
  );
  const {
    pageLoaded,
    productSeries,
    productGroup,
    productInfo,
    images,
    productDetails,
    style,
    icons,
    productFields,
    productTreatments,
    compatibleProducts,
    relatedProducts,
    metalColumns,
    strapiChart,
    TopText,
    BottomText,
    ChartSizeSmall,
    circleDimension,
    advertisement,
  } = productState;
  const productCode = props.ProductStyleCode;
  const frontImageUrl = constants.CMS_FRONT_IMAGE_URL;

  //initial page loads
  function createProductDefaults(product) {
    var productDefaults = {};
    productDefaults = {
      ...productDefaults,
      product: product.product,
      description: product.description,
      treatments: "",
      dimension: setInitialDimension(product),
      quantity: setInitialQuantity(product),
      quantity_unit: setInitialQuantityUnit(product),
    };

    const dispatchFields = [
      "product",
      "description",
      "treatments",
      "dimension",
      "quantity",
      "quantity_unit",
    ];

    productDispatch({
      type: "setStyle",
      payload: product,
    });

    dispatchFields.forEach((field) => {
      productDispatch({
        type: "setProductDefaults",
        field: field,
        payload: productDefaults[field],
      });
    });

    product.fields.forEach(function (field) {
      if (field.name !== "dimension" && field.name !== "quantity") {
        if (field.template === "field_with_units") {
          productDispatch({
            type: "setProductDefaults",
            field: field.name,
            payload: field.default,
          });
          productDispatch({
            type: "setProductDefaults",
            field: `${field.name}_unit`,
            payload: field.default_unit,
          });
          productDefaults = {
            ...productDefaults,
            [field.name]: field.default,
            [`${field.name}_unit`]: field.default_unit,
          };
        } else {
          productDispatch({
            type: "setProductDefaults",
            field: field.name,
            payload: field.default,
          });
          productDefaults = {
            ...productDefaults,
            [field.name]: field.default,
          };
        }
      }
    });
    if (authToken) {
      getInitialPrice(productDefaults);
    } else {
      productDispatch({ type: "setPriceLoaded", payload: true });
    }
  }

  function isCircle(productCode) {
    return productCode === "CIR" || productCode === "CIRCLES";
  }
  function isCharm(productCode) {
    return productCode && productCode.match(/^CHM/);
  }
  function setInitialDimension(initialProduct) {
    if (isCircle(productCode)) {
      const cirDim = initialProduct.fields.find(function (field) {
        return field.name === "dimension";
      });
      productDispatch({
        type: "setCircleDimension",
        payload: cirDim.default,
      });
      return "01x20GA";
    } else if (productCode === "WAS") {
      return "03x01x20GA";
    } else if (isCharm(productCode)) {
      return `${initialProduct.dimension}x20GA`;
    } else if (productCode === "FS") {
      return "1INx1INx20GA";
    } else if (productCode === "RW") {
      return ".032IN";
    } else if (
      productCode === "DSK" ||
      productCode === "AE" ||
      productCode === "ML" ||
      productCode === "SD" ||
      productCode === "SDC" ||
      productCode === "SR" ||
      productCode === "SDB" ||
      productCode === "SRB"
    ) {
      const dimField = initialProduct.fields.find(function (field) {
        return field.name === "dimension";
      });
      return dimField.default;
    } else if (
      props.ProductGroup === "WireTubing" ||
      props.ProductGroup === "Tubing"
    ) {
      const dimField = initialProduct.fields.find(function (field) {
        return field.name === "dimension";
      });
      return dimField.default;
    } else {
      return initialProduct.dimension;
    }
  }

  function setInitialQuantity(initialProduct) {
    const pieces = initialProduct.fields.find(function (field) {
      return field.name === "quantity.pieces";
    });
    const length = initialProduct.fields.find(function (field) {
      return field.name === "quantity.length";
    });
    const quantityLengthField = initialProduct.fields.find(function (field) {
      return field.name === "quantity.length";
    });
    const quantityField = initialProduct.fields.find(function (field) {
      return field.name === "quantity";
    });
    const quantity =
      pieces && length
        ? `${pieces.default}-${length.default}`
        : quantityLengthField
        ? quantityLengthField.default
        : quantityField.default;
    return quantity;
  }

  function setInitialQuantityUnit(initialProduct) {
    if (
      props.ProductGroup === "WireTubing" ||
      props.ProductGroup === "Tubing"
    ) {
      const quantityField = initialProduct.fields.find(function (field) {
        return field.name === "quantity.length";
      });
      return quantityField.default_unit;
    } else {
      const quantityField = initialProduct.fields.find(function (field) {
        return field.name === "quantity";
      });
      return quantityField.default_unit;
    }
  }

  function makeInitialQueryString(productDefaults) {
    const query = qs.stringify({
      product: productDefaults.product,
      dimension: productDefaults.dimension,
      quantity: productDefaults.quantity,
      quantity_unit: productDefaults.quantity_unit,
      material: productDefaults.material,
      carat_size: productDefaults.carat_size,
      message: productDefaults.message,
      finger_size: productDefaults.finger_size,
      treatments: "",
    });
    return query;
  }

  function getInitialPrice(productDefaults) {
    const query = makeInitialQueryString(productDefaults);
    api.fetch(`quickorder/price?${query}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = [];
        res.response.data.errors.forEach(function (error) {
          errorMessage.push(error);
        });
        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        productDispatch({
          type: "setMessage",
          payload: {
            message: Object.values(res.response.data.errors),
            type: "error",
          },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      } else {
        productDispatch({ type: "setPrice", payload: res.data });
        productDispatch({ type: "setPriceLoaded", payload: true });
      }
    });
  }

  //update functions
  function checkWasherDimensions() {
    const outer = Number(productInfo.outer_diameter);
    const inner = Number(productInfo.inner_diameter);
    if (inner > outer) {
      return `${productInfo.inner_diameter}x${productInfo.outer_diameter}x${productInfo.thickness}${productInfo.thickness_unit}`;
    } else {
      return `${productInfo.outer_diameter}x${productInfo.inner_diameter}x${productInfo.thickness}${productInfo.thickness_unit}`;
    }
  }

  function setDimension() {
    if (isCircle(productCode)) {
      return `${circleDimension}x${productInfo.thickness}${productInfo.thickness_unit}`;
    } else if (productCode === "WAS") {
      return checkWasherDimensions();
    } else if (productCode === "FS") {
      return `${productInfo.length}${productInfo.length_unit}x${productInfo.width}${productInfo.width_unit}x${productInfo.thickness}${productInfo.thickness_unit}`;
    } else if (isCharm(productCode)) {
      const dimension = productInfo.dimension.split("x")[0];
      return `${dimension}x${productInfo.thickness}${productInfo.thickness_unit}`;
    } else {
      return productInfo.dimension;
    }
  }

  function setTreatmentsString() {
    const treatmentsList = Array.isArray(productInfo.treatments)
      ? productInfo.treatments.map((treatment) => treatment.value)
      : [];
    return treatmentsList.join(" ");
  }

  function makeQueryString() {
    const infoToStringify = {
      product: productInfo.product,
      dimension: setDimension(),
      quantity:
        props.ProductGroup === "WireTubing" || props.ProductGroup === "Tubing"
          ? `${productInfo["quantity.pieces"]}-${productInfo["quantity.length"]}`
          : productInfo.quantity,
      quantity_unit:
        props.ProductGroup === "WireTubing" || props.ProductGroup === "Tubing"
          ? productInfo["quantity.length_unit"]
          : productInfo.quantity_unit,
      material: productInfo.material,
      carat_size: productInfo.carat_size,
      message: productInfo.message,
      finger_size: productInfo.finger_size,
      treatments: setTreatmentsString(),
    };
    const arrayOfValues = Object.values(infoToStringify);
    const searchingForNone = arrayOfValues.includes("None");
    if (!searchingForNone) {
      const query = qs.stringify(infoToStringify);
      return query;
    } else {
      productDispatch({ type: "setPriceLoaded", payload: true });
      return false;
    }
  }

  function getUpdatedPrice(newQueryString) {
    api.fetch(`quickorder/price?${newQueryString}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = [];
        if (Array.isArray(res.response.data.errors)) {
          res.response.data.errors.forEach(function (error) {
            errorMessage.push(error);
          });
        } else {
          errorMessage.push(Object.values(res.response.data.errors));
        }

        toast.error(
          `There was an error processing your request. ${errorMessage.toString()}`
        );
        productDispatch({
          type: "setMessage",
          payload: {
            message: Object.values(res.response.data.errors),
            type: "error",
          },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      } else {
        if (
          productCode !== "FS" &&
          productCode !== "ALL" &&
          productCode !== "WRB" &&
          productCode !== "RW"
        ) {
          updateFields(res.data.fields, productFields, productDispatch);
        }
        productDispatch({
          type: "setNewTreatmentFields",
          payload: res.data.treatments,
        });
        productDispatch({ type: "updatePrice", payload: res.data });
        productDispatch({
          type: "setMessage",
          payload: { message: null, type: null },
        });
        productDispatch({ type: "setPriceLoaded", payload: true });
      }
    });
  }

  function updateFields(fields) {
    const fieldNames = [
      "material",
      "length",
      "width",
      "thickness",
      "outer_diameter",
      "inner_diameter",
      "quantity",
      "dimension",
      "quantity.pieces",
      "quantity.length",
      "carat_size",
      "finger_size",
    ];

    const filteredFields = fields.filter((field) =>
      fieldNames.includes(field.name)
    );

    const sortedFields = sortFields(filteredFields);

    productDispatch({
      type: "setNewFields",
      payload: sortedFields,
    });
  }

  function sortFields(fields) {
    const sortOrderMap = {
      material: 1,
      dimension: 2,
      carat_size: 3,
      finger_size: 4,
      quantity: 5,
      outer_diameter: 10,
      inner_diameter: 11,
      "quantity.pieces": 12,
      "quantity.length": 13,
      length: 20,
      width: 21,
      thickness: 22,
    };

    const newSortedFields = fields.map((field) => ({
      ...field,
      SortOrder: sortOrderMap[field.name] || 100,
    }));

    return newSortedFields.sort((a, b) => a.SortOrder - b.SortOrder);
  }

  //initial load
  useEffect(() => {
    productDispatch({ type: "setPageLoaded", payload: false });
    productDispatch({ type: "setPriceLoaded", payload: false });
    productDispatch({ type: "setValidationErrors", payload: [] });
    productDispatch({
      type: "setMessage",
      payload: { message: null, type: null },
    });
    api.fetch(`quickorder/form?style=${productCode}`).then((res) => {
      if (res.isAxiosError) {
        const errorMessage = [];

        (res.response.data.errors || []).forEach((error) => {
          errorMessage.push(error);
        });
        if (errorMessage.length === 0) {
          toast.error(
            "There was an error processing your request. This page does not exist. Redirecting to home page."
          );
          setTimeout(() => {
            window.location.href = "/";
          }, 3000);
        } else {
          toast.error(
            `There was an error processing your request. ${
              errorMessage.length ? errorMessage : "This page does not exist."
            }`
          );
        }
      } else {
        createProductDefaults(res.data);
        functions.storeRecentlyViewed(res.data);
        productDispatch({ type: "setPageLoaded", payload: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  //strapi
  useEffect(() => {
    if (
      props.isMill ||
      productCode === "CIR" ||
      productCode === "CIRCLES" ||
      productCode === "WAS" ||
      productCode === "WRB"
    ) {
      const strapiId = getProductCodeStrapiId(productCode);
      const query = qs.stringify({
        populate: {
          left: "*",
          middle: "*",
          right: "*",
          chart: "*",
        },
      });
      api
        .fetchStrapi(`/product-details-charts/${strapiId}?${query}`)
        .then((response) => {
          productDispatch({
            type: "setStrapi",
            payload: {
              left: response.data.data.attributes.left,
              middle: response.data.data.attributes.middle,
              right: response.data.data.attributes.right,
              chart: response.data.data.attributes.chart,
              ChartSizeSmall: response.data.data.attributes.ChartSizeSmall,
              TopText: response.data.data.attributes.TopText,
              BottomText: response.data.data.attributes.BottomText,
            },
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isMill, props.match.params.ProductStyleCode]);

  // Info Bubbles from Strapi
  useEffect(() => {
    if (props.match.params.ProductStyleCode === "RW") {
      const query = qs.stringify({
        populate: {
          Product: {
            populate: "*",
          },
          bubble: {
            populate: "*",
          },
        },
      });
      api.fetchStrapi(`/info-bubbles/1?${query}`).then((response) => {
        productDispatch({
          type: "setStrapiInfoBubbles",
          payload: {
            Product: response.data.data.attributes.Product,
            bubbles: response.data.data.attributes.bubble,
          },
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  //advertisements from Strapi
  useEffect(() => {
    const query = qs.stringify({
      populate: {
        One: {
          populate: "*",
        },
        Two: {
          populate: "*",
        },
        Three: {
          populate: "*",
        },
        Four: {
          populate: "*",
        },
        Five: {
          populate: "*",
        },
      },
    });
    api
      .fetchStrapi(`/detail-page-ad?${query}`)
      .then((response) => {
        const ads = response.data.data.attributes;
        const randomAd = filterAds(ads);

        productDispatch({
          type: "setPageAd",
          payload: randomAd,
        });
      })
      .catch(() => {
        productDispatch({
          type: "setPageAd",
          payload: false,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.ProductStyleCode]);

  //update price
  useEffect(() => {
    if (authToken && pageLoaded) {
      if (
        props.ProductGroup === "WireTubing" ||
        props.ProductGroup === "Tubing"
      ) {
        productDispatch({ type: "setPriceLoaded", payload: false });
        const newQueryString = makeQueryString();
        newQueryString !== false && getUpdatedPrice(newQueryString);
      } else if (productInfo.quantity > 0) {
        productDispatch({ type: "setPriceLoaded", payload: false });
        const newQueryString = makeQueryString();
        newQueryString !== false && getUpdatedPrice(newQueryString);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo]);

  return (
    <ProductsContext.Provider value={{ productState, productDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={6}
            sx={{
              marginBottom: "1rem",
              marginTop: "1rem",
            }}
          >
            <Grid item xs={12} md={5}>
              <Stack
                spacing={1}
                sx={{ marginBottom: ".5rem" }}
                textAlign={"center"}
              >
                {isMdScreen ? (
                  <>
                    <ProductTitle
                      style={style}
                      productInfo={productInfo}
                      icons={icons}
                      location={"center"}
                    />
                    <Divider />
                  </>
                ) : null}

                <ProductGalleryViewer
                  images={
                    productCode === "WAS"
                      ? [WasherImage]
                      : productCode === "CIRCLES"
                      ? [CircleImage]
                      : productCode === "WRB"
                      ? [RingBlanks1, RingBlanks2]
                      : images
                  }
                />
                <DetailTable data={productDetails} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ marginBottom: ".5rem" }}>
                {!isMdScreen ? (
                  <ProductTitle
                    style={style}
                    productInfo={productInfo}
                    icons={icons}
                  ></ProductTitle>
                ) : null}
              </Stack>
              <Stack spacing={3}>
                {productFields &&
                  productFields.map((field, index) => {
                    return (
                      <RenderField
                        field={field}
                        key={field.name}
                        uniqueKey={index}
                        isMill={props.isMill}
                      ></RenderField>
                    );
                  })}
                {productTreatments.length > 0 && <Divider flexItem />}
                {productTreatments &&
                  productTreatments.map((treat) => {
                    return (
                      <TreatmentField
                        field={treat}
                        key={treat.label}
                        isMill={props.isMill}
                      />
                    );
                  })}
                {productTreatments.length > 0 && <Divider flexItem />}
                <SpecialInstructions />
                {props.location.state &&
                  props.location.state.showBAR &&
                  compatibleProducts.length > 0 && (
                    <GALinkButton
                      pathTo={`/ring/build/${style}`}
                      state={{
                        productGroup: productGroup,
                        baring: true,
                        recentlyViewed: JSON.parse(
                          storage.getSessionStorageItem(
                            "hsRecentlyViewedProducts"
                          )
                        ),
                      }}
                      styleInfo={{ width: "100%" }}
                      color="primary"
                      variant="contained"
                      buttonText="Build A Ring"
                      gaEventName="build_a_ring_button"
                    />
                  )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Price isMill={props.isMill} ProductGroup={props.ProductGroup} />
              {advertisement && (
                <a
                  href={advertisement.alternativeText} // Provide the external URL here
                  target="_blank" // Opens the link in a new tab/window
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ marginTop: "4rem" }}
                    src={`${frontImageUrl}${advertisement.url}`}
                    width="100%"
                    alt="advertisement"
                  />
                </a>
              )}
              {/* <PrintDetails
                  className="float-right"
                  product={productInfo}
                  images={images}
                  metalColumns={metalColumns}
                  strapiChart={strapiChart}
                  productSeries={productSeries}
                /> */}
            </Grid>
          </Grid>
          <Divider flexItem />
          {!props.isMill && (
            <CompatibleProduct compatibleProducts={compatibleProducts} />
          )}
          <RelatedItems relatedProducts={relatedProducts} />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TechnicalArticles />
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomerTestimonials />
            </Grid>
          </Grid>
          <RecentlyView />
          {metalColumns && <MetalColumns metalColumns={metalColumns} />}
          {strapiChart && (
            <StrapiChart
              strapiChart={strapiChart}
              TopText={TopText}
              BottomText={BottomText}
              ChartSizeSmall={ChartSizeSmall}
            />
          )}
          {!props.isMill && productSeries !== false && (
            <ProductSeries data={productSeries} />
          )}
        </Container>
      )}
    </ProductsContext.Provider>
  );
}
