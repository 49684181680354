// Third-party libraries
import React, { useState, useEffect, useReducer } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useForm } from "react-hook-form";
import remarkGfm from "remark-gfm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Container,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

// Local modules
import { api } from "../../../utils";
import { FormContext } from "../../../utils/stateHandlers/contexts";
import { formReducer } from "../../../utils/stateHandlers/reducers";
import states from "../../../utils/stateHandlers/initialStates";
import Banner from "../../../assets/images/DiaPurchasingTopBanner.jpg";

const schema = yup.object().shape({
  Version: yup.string(),
  ContactName: yup.string().required("Contact Name is Required"),
  CompanyName: yup.string().required("Company Name is Required"),
  Email: yup.string().required("Email is Required"),
  Phone: yup.string().required("Phone Number is Required"),
  Street1: yup.string().required("Address is Required"),
  Street2: yup.string(),
  City: yup.string().required("City is Required"),
  Region: yup
    .string()
    .test(
      "weight-unit-not-selected",
      "State is Required",
      (value) => value !== "None"
    )
    .required("State is Required"),
  PostalCode: yup.string().required("Zip Code is Required"),
  CountryCode: yup.string(),
  PackageType: yup
    .string()
    .test(
      "weight-unit-not-selected",
      "Package Type is Required",
      (value) => value !== "None"
    )
    .required("Package Type is Required"),
  DropoffType: yup
    .string()
    .test(
      "weight-unit-not-selected",
      "Dropoff Option is Required",
      (value) => value !== "None"
    )
    .required("Dropoff Option is Required"),
  CaratWeight: yup
    .string()
    .required("Carat Weight is Required and Cannot Be Less Than 1"),
  InsuredValue: yup.string().required("Value for Insurance is Required"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export default function StonePurchaseService(props) {
  const [formState, dispatch] = useReducer(
    formReducer,
    states.initialFormState
  );
  const {
    isLoaded,
    fields,
    formtext,
    formtitle,
    formInfo,
    diamondshippingtext,
    addressFields,
  } = formState;
  const [expanded, setExpanded] = useState("shipping");
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const InputField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography variant="navText">
        {field.name === "Street1"
          ? "Address"
          : field.name === "Street2"
          ? "Street 2"
          : field.name === "PostalCode"
          ? "Zip Code"
          : field.label}
      </Typography>
      <TextField
        {...register(field.name)}
        error={errors[field.name] ? true : false}
        helperText={errors[field.name] ? errors[field.name].message : ""}
        value={Object.values(formInfo).find(
          (formField) => formField === field.name
        )}
        color="primary"
        size="small"
        sx={{
          width: "100%",
          margin: "unset",
        }}
      />
    </Stack>
  );

  const SelectField = (field) => (
    <Stack sx={{ margin: "1rem" }}>
      <Typography variant="navText">
        {field.label === "Region" ? "State" : field.label}
      </Typography>
      <FormControl
        {...register(field.name)}
        error={errors[field.name] ? true : false}
      >
        <Select
          key={field.name}
          size="small"
          defaultValue="None"
          MenuProps={{
            PaperProps: {
              sx: {
                border: "1px solid #939598",
                borderRadius: "0",
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
          onChange={(e) =>
            setValue(field.name, e.target.value, { shouldValidate: true })
          }
        >
          <MenuItem value="None">
            <em>Select...</em>
          </MenuItem>
          {field.values &&
            field.values.map((option) => (
              <MenuItem value={option.value} key={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText>
          {errors[field.name] ? errors[field.name].message : ""}
        </FormHelperText>
      </FormControl>
    </Stack>
  );

  function submitForm(data) {
    const formThings = {
      Version: "Diamonds",
      ContactName: data.ContactName,
      CompanyName: data.CompanyName,
      Email: data.Email,
      Phone: data.Phone,
      Address: {
        Street1: data.Street1,
        Street2: data.Street2,
        City: data.City,
        Region: data.Region,
        PostalCode: data.PostalCode,
        CountryCode: "US",
      },
      PackageType: data.PackageType,
      DropoffType: data.DropoffType,
      CaratWeight: data.CaratWeight,
      InsuredValue: data.InsuredValue,
    };
    api.pdfDownload(`/forms/shipping/submit`, formThings).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "shipping-label.pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    api.fetchStrapi(`/diamond-forms/2?populate=*`).then((response) => {
      dispatch({
        type: "setFormCopy",
        payload: {
          formtitle: response.data.data.attributes.title,
          formtext: response.data.data.attributes.body,
          formimage: response.data.data.attributes.image,
        },
      });
    });

    api.fetchStrapi(`/diamond-forms/1?populate=*`).then((response) => {
      dispatch({
        type: "setDiamondShippingCopy",
        payload: {
          diamondshippingtitle: response.data.data.attributes.title,
          diamondshippingtext: response.data.data.attributes.body,
        },
      });
    });

    api.fetch(`forms/shipping/fields?version=diamonds`).then((response) => {
      const fields = [].concat(...response.data.fields);
      const removeBlankLabels = fields.filter(function (field) {
        return field.label !== "";
      });
      const removeAddress = removeBlankLabels.filter(function (field) {
        return field.label !== "Address";
      });
      const addressFields = fields.filter(function (field) {
        return field.label === "" || field.label === "Address";
      });
      const fixAddressFieldNames = addressFields.map(function (af) {
        const newName = af.name.split(".", 2);
        return {
          ...af,
          name: newName[1],
          label: newName[1],
        };
      });
      dispatch({ type: "setFields", payload: removeAddress });
      dispatch({ type: "setAddressFields", payload: fixAddressFieldNames });
      fields.forEach(function (field) {
        dispatch({
          type: "updateFormInfo",
          field: field.name,
          payload: field.default,
        });
      });
      dispatch({ type: "setIsLoaded", payload: true });
    });
  }, []);

  return (
    <FormContext.Provider value={{ formState, dispatch }}>
      {!isLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl" className="careers-text">
          <Stack spacing={2} sx={{ marginTop: "2rem" }}>
            <img src={Banner} width="100%" alt="banner" />
            <Typography variant="articleTitle" className="center">
              {formtitle}
            </Typography>
            <Typography
              variant="articleText"
              sx={{ marginBottom: "2rem", paddingTop: "3rem" }}
            >
              <ReactMarkdown children={formtext} remarkPlugins={[remarkGfm]} />
            </Typography>
            <Accordion
              expanded={expanded === "shipping"}
              onChange={handleChange("shipping")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor: "#cfdde9" }}
              >
                <Typography variant="h3">
                  Ship your diamonds to us for FREE!!
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={handleSubmit(submitForm)}>
                  <Stack>
                    <Typography
                      variant="articleText"
                      sx={{ marginBottom: "1rem" }}
                    >
                      <ReactMarkdown
                        children={diamondshippingtext}
                        remarkPlugins={[remarkGfm]}
                      />
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <Stack width="100%" spacing={2}>
                        <Typography variant="h6">
                          Company Information:
                        </Typography>
                        {fields &&
                          fields.map((field) => (
                            <React.Fragment key={field.name}>
                              {field.template === "text"
                                ? InputField(field)
                                : field.template === "phone"
                                ? InputField(field)
                                : field.template === "number"
                                ? InputField(field)
                                : field.template === "dropdown"
                                ? SelectField(field)
                                : ""}
                            </React.Fragment>
                          ))}
                      </Stack>
                      <Stack width="100%" spacing={2}>
                        <Typography variant="h6">Shipping Address:</Typography>
                        {addressFields &&
                          addressFields.map((field) => (
                            <React.Fragment key={field.name}>
                              {field.template === "text"
                                ? InputField(field)
                                : field.template === "phone"
                                ? InputField(field)
                                : field.template === "number"
                                ? InputField(field)
                                : field.template === "dropdown"
                                ? SelectField(field)
                                : ""}
                            </React.Fragment>
                          ))}
                      </Stack>
                    </Stack>
                    <Button
                      variant="contained"
                      type="submit"
                      endIcon={<FileDownloadIcon />}
                      sx={{ marginTop: "1.5rem" }}
                    >
                      Download
                    </Button>
                  </Stack>
                </form>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "quote"}
              onChange={handleChange("quote")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                sx={{ backgroundColor: "#cfdde9" }}
              >
                <Typography variant="h3">Request a FREE quote</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="articleText" sx={{ marginBottom: "1rem" }}>
                  Use the form below to get a broad quote range for your stones.
                </Typography>
                <iframe
                  title="StonePurchaseServiceForm"
                  width="100%"
                  height="500px"
                  src="https://form.jotform.com/210691912444454"
                />
              </AccordionDetails>
            </Accordion>
          </Stack>
        </Container>
      )}
    </FormContext.Provider>
  );
}
