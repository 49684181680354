// Third-party libraries
import React, { useContext, useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import MultiSelectField from "./MultiSelectField";
import ToggleSelectField from "./ToggleSelectField";
import ListSelectField from "./ListSelectField";
// Local modules

import { ProductLandingContext } from "../../../utils/stateHandlers/contexts";
import placeholder from "../../../../src/assets/images/placeHolder.png";
const placeHolder = placeholder;

const fieldView = [
  { view: "ListView", fields: ["Material"] },
  {
    view: "ToggleView",
    fields: [
      "Weight",
      "FitType",
      "FinishingType",
      "MfgMethod",
      "BandEndType",
      "StonesRowCount",
      "Drills",
      "AdvancedOptions",
    ],
  },
];

export default function ProductFilter(props) {
  const { landingState, landingDispatch } = useContext(ProductLandingContext);
  const { searchQuery, clearAll } = landingState;
  const [selected, setSelected] = useState([]);
  const filter = props.filter;
  const state = props.state;

  function updateFilters(filterName, filterValues) {
    if (filter.name === filterName) {
      setSelected(filterValues);
      landingDispatch({ type: "setClearAll", payload: false });
      const filterToUpdate = searchQuery.fields.find(
        (field) => field.name === filterName
      );
      if (filterToUpdate) {
        filterToUpdate.values = filterValues;
        landingDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
      } else {
        const fields = [
          ...searchQuery.fields,
          {
            name: filterName,
            values: filterValues,
          },
        ];

        landingDispatch({ type: "updateSearchQuery", payload: fields });
      }
    }
  }

  function updateInitialFilters(filterName, filterValues) {
    if (filter.name === filterName) {
      filterValues = filterValues.map((filterValue) => filterValue.value);
      setSelected(filterValues);
      landingDispatch({ type: "setClearAll", payload: false });
      const filterToUpdate = searchQuery.fields.find(
        (field) => field.name === filterName
      );
      if (filterToUpdate) {
        filterToUpdate.values = filterValues;
        landingDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
      } else {
        const fields = [
          ...searchQuery.fields,
          {
            name: filterName,
            values: filterValues,
          },
        ];

        landingDispatch({ type: "updateSearchQuery", payload: fields });
      }
    }
  }
  useEffect(() => {
    if (clearAll) {
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAll]);

  useEffect(() => {
    if (state !== undefined) {
      let filterName = Object.keys(state)[0];
      let filterValues = state[filterName];
      updateInitialFilters(filterName, filterValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getViewForField = (fieldName) => {
    const result = fieldView.find((item) => item.fields.includes(filter.name));
    return result ? result.view : "default";
  };

  return (
    <>
      {(() => {
        const view = getViewForField(filter.name);
        if (
          view === "default" &&
          props.category === "All Bands" &&
          filter.values.length !== 0
        ) {
          return (
            <MultiSelectField
              key={filter.name}
              name={filter.name}
              label={filter.label}
              value={selected}
              onChange={(e) => {
                updateFilters(filter.name, e.target.value);
              }}
              options={filter.values}
              imageUrl={placeHolder}
              category={props.category}
            />
          );
        }

        if (view === "ListView" && props.category === "All Bands") {
          return (
            <Grid item xl={12}>
              <ListSelectField
                key={filter.name}
                name={filter.name}
                label={filter.label}
                value={selected}
                updateFilters={updateFilters}
                options={filter.values}
                imageUrl={placeHolder}
              />
            </Grid>
          );
        }

        if (
          view === "ToggleView" &&
          props.category === "All Bands" &&
          filter.values.length !== 0
        ) {
          return (
            <Grid item lg={12}>
              <Stack
                direction={"column"}
                sx={{
                  margin: "0 1rem 1rem",
                }}
              >
                <ToggleSelectField
                  key={filter.name}
                  name={filter.name}
                  label={filter.label}
                  value={selected}
                  updateFilters={updateFilters}
                  options={filter.values}
                  imageUrl={placeHolder}
                />
              </Stack>
            </Grid>
          );
        }

        if (props.category !== "All Bands") {
          return (
            <MultiSelectField
              key={filter.name}
              name={filter.name}
              label={filter.label}
              value={selected}
              onChange={(e) => {
                updateFilters(filter.name, e.target.value);
              }}
              options={filter.values}
              imageUrl={placeHolder}
              category={props.category}
            />
          );
        }
        return null;
      })()}
    </>
  );
}
