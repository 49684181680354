// Third-party libraries
import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Skeleton,
} from "@mui/material";

// Local modules
import {
  BandsContext,
  AppContext,
} from "../../../../utils/stateHandlers/contexts";
import CatalogPageButton from "../../shared/details/CatalogPageButton";

export default function BandsDetailsTable(props) {
  const { authToken } = useContext(AppContext);
  const { bandsState } = useContext(BandsContext);
  const { priceLoaded, productDetails } = bandsState;
  const rowCount = props.product === "A" ? 14 : 7;
  const columnCount = 2;

  const hasHSProvider = productDetails.some(
    (item) => item.label === "Stone  Provided By" && item.value === "H&S"
  );

  const filteredDetails = productDetails.filter(
    (item) =>
      item.label !== "Stone  Type" &&
      item.label !== "Stone  Shape" &&
      !(item.label === "Stone   Quality" && !hasHSProvider)
  );

  function existingLabel(productDetails, label) {
    return productDetails.find((item) => item.label === label);
  }

  function calcStoneSize(stoneSize, stoneQuantity) {
    return parseFloat((stoneSize * stoneQuantity).toFixed(2));
  }

  function addProductDetails(productDetails) {
    const stoneQuantityItem = existingLabel(productDetails, "Stone  Quantity");
    const stoneSizeItem = existingLabel(
      productDetails,
      "Stone  Size (ct - mm)"
    );

    const stoneQuantity = stoneQuantityItem
      ? Number(stoneQuantityItem.value)
      : NaN;
    const stoneSize = stoneSizeItem
      ? Number(stoneSizeItem.value.split(" - ")[0])
      : NaN;
    const totalCTWeight = calcStoneSize(stoneSize, stoneQuantity);

    if (!isNaN(totalCTWeight)) {
      const totalCaratWeightItem = existingLabel(
        productDetails,
        "Total Carat Weight"
      );

      if (!totalCaratWeightItem) {
        productDetails.push({
          label: "Total Carat Weight",
          value: `${totalCTWeight} CTW`,
        });
      } else if (totalCaratWeightItem.value !== `${totalCTWeight} CTW`) {
        totalCaratWeightItem.value = `${totalCTWeight} CTW`;
      }
    }
  }

  addProductDetails(filteredDetails);

  const renderSkeletonRows = (rowCount, columnCount) =>
    [...Array(rowCount)].map((_, rowIndex) => (
      <TableRow key={rowIndex}>
        {[...Array(columnCount)].map((_, colIndex) => (
          <TableCell key={colIndex}>
            <Skeleton variant="text" width="10rem" className="float-right" />
          </TableCell>
        ))}
      </TableRow>
    ));

  return (
    <>
      <Typography variant="h2" sx={{ width: "100%", marginTop: "2rem" }}>
        <div className="details-header">
          <span>Details</span>
          <CatalogPageButton productStyle={bandsState.style} />
        </div>
      </Typography>
      {authToken ? (
        <Table size="small" sx={{ width: "100%" }}>
          <TableBody>
            {priceLoaded
              ? filteredDetails?.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{data.label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{data.value}</Typography>
                    </TableCell>
                  </TableRow>
                ))
              : renderSkeletonRows(rowCount, columnCount)}
          </TableBody>
        </Table>
      ) : (
        <Typography sx={{ margin: "1rem" }}>
          To see the details of a specific product, click the "Login For
          Pricing" button at the top right of the screen.
        </Typography>
      )}
    </>
  );
}
