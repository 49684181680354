// Third-party libraries
import React, { useState, useEffect, useContext } from "react";

import { Link as DomLink, useLocation } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import OpenInNewSharpIcon from "@mui/icons-material/OpenInNewSharp";
import CloseIcon from "@mui/icons-material/Close";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import {
  Grid,
  Box,
  Container,
  Checkbox,
  TextField,
  FormControlLabel,
  Stack,
  Divider,
  CircularProgress,
  Card,
  CardContent,
  Backdrop,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Chip,
} from "@mui/material";

// Local modules
import { CartContext } from "../../utils/stateHandlers/contexts";
import api from "../../utils/api";
import storage from "../../utils/storageFactory";
import formatting from "../../utils/formatHelpers";
import StringConstants from "../../utils/constants";
import MediaPanel from "./controls/MediaPanel";
import DiamondIconStrip from "./controls/DiamondIconStrip";
import RecentlyViewedDiamondLink from "./controls/RecentlyViewedDiamondLink";
import Modal from "./controls/Modal";
import useMediaQueryUtils from "../../utils/mediaQueryUtils";

export default function CertDiamondDetails(props) {
  const { cartDispatch } = useContext(CartContext);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [diamond, setDiamond] = useState({});
  const [fields, setFields] = useState({});
  const [conditionalSale, setConditionalSale] = useState(false);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [rvCounter, setRvCounter] = useState(0);
  const [showOFCHelp, setShowOFCHelp] = useState(false);
  const [addedToCart, setAddedToCart] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [terms, setTerms] = useState(false);
  const isMdScreen = useMediaQueryUtils("md");

  const mined = StringConstants.DIAMOND_MINED_OR_LAB_MINED_DESC;
  const lab = StringConstants.DIAMOND_MINED_OR_LAB_LAB_DESC;
  const loggedIn = storage.getStorageItem("authToken") ? true : false;
  const sendTo = useLocation();

  useEffect(() => {
    api
      .fetch(`diamonds/detail/${props.match.params.HooverStockId}`)
      .then((res) => {
        setDiamond(res.data);
      })
      .then(
        api.fetch("diamonds/fields").then((res) => {
          setFields(res.data);
          setPageLoaded(true);
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recentlyViewedData = JSON.parse(
    storage.getSessionStorageItem("hsRecentlyViewedData")
  );

  let rvPics = "";

  if (recentlyViewedData && recentlyViewedData.length > 2) {
    rvPics = (
      <>
        <RecentlyViewedDiamondLink
          key={recentlyViewedData[rvCounter + 0].HooverStockId}
          rv={recentlyViewedData[rvCounter + 0]}
        />
        <RecentlyViewedDiamondLink
          key={recentlyViewedData[rvCounter + 1].HooverStockId}
          rv={recentlyViewedData[rvCounter + 1]}
        />
        <RecentlyViewedDiamondLink
          key={recentlyViewedData[rvCounter + 2].HooverStockId}
          rv={recentlyViewedData[rvCounter + 2]}
        />
      </>
    );
  }

  function goRVRight() {
    if (rvCounter < recentlyViewedData.length - 3) {
      setRvCounter(rvCounter + 1);
    }
  }

  function goRVLeft() {
    if (rvCounter > 0) {
      setRvCounter(rvCounter - 1);
    }
  }

  function addToCart() {
    if (!terms) {
      setShowAlert(true);
    } else {
      const addToCartBody = {
        cart_id:
          storage.getStorageItem("cartId") === "null" ||
          storage.getStorageItem("cartId") === "undefined"
            ? localStorage.removeItem("cartId")
            : storage.getStorageItem("cartId"),
        partcode: {
          material: "D",
          product: diamond.Shape,
          dimension: `${diamond.Color}${diamond.Clarity}`,
          finger_size: parseInt(diamond.HooverStockId),
          quantity: "1",
          quantity_unit: "EA",
          carat_size: diamond.Carat,
          custom_dimension: {
            sale: conditionalSale ? 0 : 1,
            terms: 1,
          },
          message: conditionalSale
            ? `Conditional Sale. ${specialInstructions}`
            : specialInstructions,
        },
      };
      api
        .post("cart/add", addToCartBody)
        .then((res) => {
          cartDispatch({
            type: "updateCart",
            payload: {
              cart: res.data.items,
              cartCount: res.data.count,
            },
          });
          if (addToCartBody.cart_id === null) {
            storage.setStorageItem("cartId", res.data.cart_id);
          }
          setAddedToCart(true);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  const checkboxesAndSpecialInstructions = () => (
    <Stack spacing={1}>
      <FormControlLabel
        control={
          <Checkbox
            checked={conditionalSale}
            onChange={() => setConditionalSale(conditionalSale ? false : true)}
          />
        }
        label={
          <Typography variant="body3" sx={{ color: "firebrick" }}>
            Order for Conditional Sale
            <HelpIcon
              className="help-icon question-mark"
              onClick={() => {
                setShowOFCHelp(true);
              }}
            />
          </Typography>
        }
        sx={{ margin: "unset" }}
      />
      <FormControlLabel
        label={
          <Typography variant="cellFont">
            I have read and agree to the{" "}
            <DomLink
              to={{ pathname: "/links/Company-Policies" }}
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Terms & Conditions
            </DomLink>{" "}
            *
          </Typography>
        }
        control={
          <Checkbox
            checked={terms}
            onChange={() => setTerms(terms ? false : true)}
          />
        }
      />
      <TextField
        color="primary"
        variant="outlined"
        size="small"
        value={specialInstructions}
        onChange={(e) => setSpecialInstructions(e.target.value)}
        inputProps={{
          style: {
            paddingBottom: 100,
          },
        }}
        label={<Typography variant="body3">Special Instructions</Typography>}
      />
    </Stack>
  );

  const specificationsBlock = () => (
    <Stack>
      <Typography variant="h5">Specifications:</Typography>
      <Divider />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            sm: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(2, 1fr)",
          },
        }}
      >
        <Typography variant="body3" sx={{ padding: ".5rem" }}>
          {diamond.Carat}ct {diamond.Shape} Shape
        </Typography>

        {/* TODO REMOVE HACK to hide IMAGELINK */}
        {fields
          .filter((f) => f.details_order !== null)
          .filter((f) => f.name !== "ImageLink")
          .filter((f) =>
            diamond.Shape === "Round"
              ? f.name !== "Girdle"
              : diamond.Shape === "Heart"
              ? f.name !== "Girdle"
              : diamond.Girdle === ""
              ? f.name !== "Girdle"
              : f
          )
          .filter((f) =>
            diamond.Shape === "Round"
              ? f.name !== "Culet"
              : diamond.Shape === "Heart"
              ? f.name !== "Culet"
              : diamond.Culet === ""
              ? f.name !== "Culet"
              : f
          )
          .filter((f) =>
            diamond.Color === ""
              ? f.name !== "Color"
              : f.name !== "FancyColor" &&
                f.name !== "FancyColorIntensity" &&
                f.name !== "FancyColorOvertone"
          )
          .sort((f1, f2) => f1.details_order - f2.details_order)
          .map((f, i) => (
            <Typography
              key={"specs_" + i}
              variant="body3"
              sx={{ padding: ".5rem" }}
            >
              {f.label}:{" "}
              {f.label === "Report" &&
              diamond.CertificationLocation.length > 0 ? (
                <>
                  {diamond[f.name]}&nbsp;&nbsp;
                  <a
                    href={diamond.CertificationLocation}
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                </>
              ) : (
                (formatting[f.format] &&
                  formatting[f.format](diamond[f.name])) ||
                diamond[f.name]
              )}
            </Typography>
          ))}
      </Box>
    </Stack>
  );

  return (
    <>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Container maxWidth="xl">
          <Stack paddingTop={"2rem"}>
            <Typography variant="articleTitle">
              {`${diamond.MinedOrLab === "L" ? lab : mined} ${
                diamond.StoneType
              }`}
              <Typography variant="h3" className="sku">
                SKU: {diamond.HooverStockId}
              </Typography>
            </Typography>
            {diamond.Vendor === "124555" && diamond.MinedOrLab === "L" && (
              <Typography variant="subtitle1">
                Ships Same Day <LocalShippingTwoToneIcon />
              </Typography>
            )}
            <Typography
              marginTop={"2rem"}
              variant={isMdScreen ? "h5" : "h6"}
              textAlign={isMdScreen ? "center" : ""}
            >
              {`${diamond.Carat}ct ${diamond.Shape} ${
                diamond.Color || diamond.FancyColor
              } ${diamond.Clarity} ${diamond.HooverCertification} Certified`}
              {!isMdScreen ? (
                <Tooltip
                  title="Shareable Page without Pricing"
                  placement="top-start"
                  className="float-right"
                >
                  <DomLink
                    id="blank-diamond-details"
                    to={{
                      pathname: `/blankdiamond/${diamond.HooverStockId}`,
                    }}
                    target="_blank"
                  >
                    <Chip
                      className="pointer"
                      icon={<OpenInNewSharpIcon />}
                      label="Shareable Page w/o Pricing"
                      color="primary"
                      variant="outlined"
                      sx={{ paddingLeft: ".5rem" }}
                    />
                  </DomLink>
                </Tooltip>
              ) : null}
            </Typography>
            <Divider
              flexItem
              sx={{ marginBottom: "2rem", marginTop: "2rem" }}
            />
            <Stack
              direction={isMdScreen ? "column" : "row"}
              spacing={8}
              justifyContent="center"
            >
              <Stack
                sx={{
                  width: "100%",
                  minHeight: "100%",
                }}
              >
                <MediaPanel
                  diamond={diamond}
                  popover={props.popover}
                  details={true}
                />
              </Stack>
              <Stack spacing={2}>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                  <Typography variant="body3">Source: </Typography>
                  <div style={{ width: "40%" }}>
                    <DiamondIconStrip
                      diamond={diamond}
                      show_text={true}
                      center={false}
                    />
                  </div>
                </Stack>
                {loggedIn ? (
                  <>
                    <Stack direction="row" spacing={3}>
                      <Typography variant="body3">
                        {`Price P/C: ${formatting["price2"](
                          diamond.CalcPricePerCarat
                        )}`}
                      </Typography>
                      {diamond.Color !== "" && (
                        <Typography variant="body3">
                          {`% Off Rap: ${formatting["percent0"](
                            diamond.CalcPercentOffRap
                          )}`}
                        </Typography>
                      )}
                    </Stack>
                    <Divider />
                    <Typography variant="h2" className="center">
                      Total Price: {formatting["price2"](diamond.HooverPrice)}
                    </Typography>
                    {checkboxesAndSpecialInstructions()}
                    {addedToCart ? (
                      <Stack
                        direction={isMdScreen ? "column" : "row"}
                        alignItems={"center"}
                        spacing={1}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => window.close()}
                        >
                          Continue Shopping
                        </Button>
                        <DomLink to={{ pathname: "/cart" }} rel="noreferrer">
                          <Button variant="contained">Checkout</Button>
                        </DomLink>
                      </Stack>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        onClick={() => addToCart()}
                        sx={{ width: "100%" }}
                      >
                        Add to Cart
                      </Button>
                    )}
                  </>
                ) : (
                  <DomLink
                    to={{
                      pathname: "/login",
                      state: { sendTo: sendTo.pathname },
                    }}
                  >
                    <Button variant="contained" color="error">
                      Login For Pricing
                    </Button>
                  </DomLink>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Grid container rowSpacing={2} justifyContent="center">
            <Grid item className="details-container">
              <Card variant="outlined" width={"100%"}>
                <CardContent className="additional-info">
                  {specificationsBlock()}
                </CardContent>
              </Card>
              {recentlyViewedData && (
                <Grid
                  container
                  className={
                    recentlyViewedData.length > 2 ? "" : "display-none"
                  }
                >
                  <Grid item xs={12}>
                    <Stack className="recently-viewed-container">
                      <Typography>Recently Viewed</Typography>
                      <Divider flexview="true" sx={{ width: "100%" }} />
                      <div className="rv-strip">
                        <div
                          className={
                            rvCounter > 0
                              ? "rv-chevrons"
                              : "rv-chevrons invisible"
                          }
                          onClick={(x) => goRVLeft()}
                        >
                          <ChevronLeftIcon sx={{ verticalAlign: "middle" }} />
                        </div>
                        {rvPics}
                        <div
                          className={
                            rvCounter + 3 < recentlyViewedData.length
                              ? "rv-chevrons"
                              : "rv-chevrons invisible"
                          }
                          onClick={(x) => goRVRight()}
                        >
                          <ChevronRightIcon sx={{ verticalAlign: "middle" }} />
                        </div>
                      </div>
                    </Stack>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Modal
            title="Loose Diamond Memo Policy:"
            onClose={() => setShowOFCHelp(false)}
            show={showOFCHelp}
          />
          <Dialog
            open={showAlert}
            onClose={() => setShowAlert(false)}
            onBackdropClick={() => setShowAlert(false)}
          >
            <DialogTitle sx={{ fontSize: "1.6rem", fontWeight: "bold" }}>
              {`Terms & Conditions`}
              <IconButton
                aria-label="close"
                onClick={() => setShowAlert(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography gutterBottom sx={{ fontSize: "1.4rem" }}>
                  Please accept the terms and conditions.
                </Typography>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </Container>
      )}
    </>
  );
}
