import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Step,
  Stepper,
  Stack,
  Typography,
  StepContent,
  StepLabel,
} from "@mui/material";
import BandsFields from "../components/bandsComponents/BandsFields";
import BandsSpecialInstructions from "../components/bandsComponents/BandsSpecialInstructions";
import CheckIcon from "@mui/icons-material/Check";

const steps = ["Band Details", "Stone Details", "Ring Treatments"];

function BandStepper({
  builder,
  complex,
  complex1,
  complex2,
  complex3,
  product,
  stoneProviderFlag,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set());
  const theme = useTheme();

  const fieldMap = {
    materialField: complex1.find((item) => item.label === "Material"),
    ringSizeField: complex.find((item) => item.label === "Ring Size"),
    stoneSizeField: complex1.find(
      (item) => item.label === "Stone Size (ct - mm)"
    ),
    stoneQuantityField: complex1.find(
      (item) => item.label === "Stone Quantity"
    ),
    widthField: complex1.find((item) => item.label === "Width (mm)"),
    stoneProviderField: complex1.find(
      (item) => item.label === "Stone Provided By"
    ),
    stoneQualityField: complex1.find(
      (item) => item.label === "Stone Quality and Type"
    ),
    finishField: complex1.find((item) => item.label === "Finish"),
    rhodiumPlatingField: complex1.find(
      (item) => item.label === "Rhodium Plating"
    ),
    karatStampField: complex.find((item) => item.label === "Karat Stamp"),
    trademarkField: complex.find((item) => item.label === "Trademark"),
  };

  const getDefaultValue = (complexArray, label) => {
    const item = complexArray.find((item) => item.label === label);
    if (!item) return null;

    const defaultValue = String(item.default ?? "");
    if (label === "Quantity") {
      return defaultValue;
    }
    const defaultLabel = item.values?.find(
      (val) => String(val.value) === defaultValue
    );
    return String(defaultLabel?.label ?? "");
  };

  const fieldSelected = {
    0: {
      Qty: getDefaultValue(builder, "Quantity"),
      Material: getDefaultValue(complex1, "Material"),
      "Ring Size": getDefaultValue(complex, "Ring Size"),
    },
    1: {
      "Stone Size": getDefaultValue(complex1, "Stone Size (ct - mm)"),
      Qty: getDefaultValue(complex1, "Stone Quantity"),
      Provider: getDefaultValue(complex1, "Stone Provided By"),
      Quality:
        getDefaultValue(complex1, "Stone Provided By") === "H&S"
          ? getDefaultValue(complex1, "Stone Quality and Type")
          : "None",
    },
    2: {
      Finish: [
        getDefaultValue(complex1, "Finish"),
        getDefaultValue(complex1, "Rhodium Plating") === "Yes"
          ? "Rhodium"
          : null,
        getDefaultValue(complex, "Karat Stamp") === "Yes"
          ? "Karat Stamp"
          : null,
        getDefaultValue(complex, "Trademark") === "Yes" ? "Trademark" : null,
      ]
        .filter(Boolean) // Remove null values
        .join(", "), // Convert array to string with comma separation
    },
  };

  const handleNext = () => {
    setCompletedSteps((prev) => new Set([...prev, activeStep]));
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setCompletedSteps((prev) => {
      const newSet = new Set(prev);
      newSet.delete(activeStep - 1);
      return newSet;
    });
    setActiveStep((prev) => prev - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompletedSteps(new Set());
  };

  const renderStepContent = (activeStep) => (
    <Stack spacing={2}>
      {/* Step 0 */}
      <Box sx={{ display: activeStep === 0 ? "block" : "none" }}>
        {fieldMap.materialField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.materialField} />
          </Box>
        )}
        {builder?.map((field) => (
          <Box key={field.name} sx={{ mb: 2 }}>
            <BandsFields field={field} />
          </Box>
        ))}
        {fieldMap.ringSizeField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.ringSizeField} />
          </Box>
        )}
      </Box>

      {/* Step 1 */}
      <Box sx={{ display: activeStep === 1 ? "block" : "none" }}>
        {fieldMap.stoneSizeField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.stoneSizeField} />
          </Box>
        )}
        {fieldMap.stoneQuantityField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.stoneQuantityField} />
          </Box>
        )}
        {product !== "A" && fieldMap.widthField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.widthField} />
          </Box>
        )}
        {fieldMap.stoneProviderField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.stoneProviderField} />
          </Box>
        )}
        {stoneProviderFlag && fieldMap.stoneQualityField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.stoneQualityField} />
          </Box>
        )}
      </Box>

      {/* Step 2 */}
      <Box sx={{ display: activeStep === 2 ? "block" : "none" }}>
        {fieldMap.finishField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.finishField} />
          </Box>
        )}
        {fieldMap.rhodiumPlatingField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.rhodiumPlatingField} />
          </Box>
        )}
        {fieldMap.karatStampField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.karatStampField} />
          </Box>
        )}
        {fieldMap.trademarkField && (
          <Box sx={{ mb: 2 }}>
            <BandsFields field={fieldMap.trademarkField} />
          </Box>
        )}
        <Box sx={{ mb: 2 }}>
          <BandsSpecialInstructions />
        </Box>
      </Box>

      {/* Step 3 */}
      <Box sx={{ display: activeStep === 3 ? "block" : "none" }}>
        {complex2?.map((field) => (
          <Box key={field.name} sx={{ mb: 2 }}>
            <BandsFields field={field} />
          </Box>
        ))}
      </Box>

      {/* Step 4 */}
      <Box sx={{ display: activeStep === 4 ? "block" : "none" }}>
        {complex3?.map((field) => (
          <Box key={field.name} sx={{ mb: 2 }}>
            <BandsFields field={field} />
          </Box>
        ))}
      </Box>
    </Stack>
  );

  const CustomStepIcon = ({ icon, active }) => {
    const isCompleted = completedSteps.has(icon - 1);
    return (
      <Box
        sx={{
          width: 30,
          height: 30,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: isCompleted
            ? theme.palette.success.main
            : active
            ? theme.palette.primary.main
            : theme.palette.secondary.dark,
          color: theme.palette.primary.contrastText,
          fontSize: "1.5rem",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {isCompleted ? <CheckIcon /> : icon}
      </Box>
    );
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              optional={
                completedSteps.has(index) ? (
                  <Typography variant="subtitle2" color="text.secondary">
                    {Object.entries(fieldSelected[index])
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(", ")}
                  </Typography>
                ) : null
              }
            >
              <Typography sx={{ fontFamily: theme.typography.body1 }}>
                {label}
              </Typography>
            </StepLabel>
            <StepContent>
              {renderStepContent(index)}
              <Stack direction="row" gap={2}>
                <Button
                  disabled={index === 0}
                  onClick={handleBack}
                  sx={{ mt: 1 }}
                >
                  Back
                </Button>
                <Button variant="contained" onClick={handleNext} sx={{ mt: 1 }}>
                  {index === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </Stack>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Box sx={{ mt: 4 }}>
          <Stack spacing={2}>
            <Typography variant="h6">All steps completed!</Typography>
            <Button onClick={handleReset} variant="contained">
              Restart
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
}

export default BandStepper;
