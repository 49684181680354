import React from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Grid, Stack, Typography } from "@mui/material";

function PopularSelections(props) {
  const { popularLinks } = props;

  return (
    <Stack className="column-stack" spacing={2}>
      <Typography variant="h3">Your Cart Is Empty</Typography>
      <Typography variant="h3">Shop Our Popular Selections</Typography>
      <Divider />
      <Grid container>
        {popularLinks.links.map((item, index) => (
          <Grid item xs={12} key={index}>
            <Button component={Link} to={item.link} variant="contained">
              {item.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}

export default PopularSelections;
