import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import placeHolder from "../../../assets/images/placeHolder.png";
import unavailable from "../../../assets/images/price_unavailable.png";
import quantity from "../../../assets/images/min_not_met.png";
import { functions } from "../../../utils";

const useStyles = makeStyles(() => ({
  customStack: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  iconStack: {
    position: "absolute",
    top: "8px",
    right: "8px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
}));

export default function ProductGalleryViewer(props) {
  const [selectedImage, setSelectedImage] = useState(placeHolder);
  const [images, setImages] = useState([]);
  const classes = useStyles();
  const errorImage =
    props.errorType === "product"
      ? unavailable
      : props.errorType === "generalError"
      ? placeHolder
      : quantity;

  useEffect(() => {
    setImages(props.images);
    props.baring
      ? setSelectedImage(props.images[1])
      : setSelectedImage(props.images[0]);
  }, [props.images, props.baring]);
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      border={"1px solid #e0e0e0"}
      position="relative"
    >
      {props.icons && (
        <Box className={classes.iconStack}>
          {props.icons.map((icon, key) => {
            const iconElement = functions.getIcons(icon);
            return iconElement ? (
              <Typography key={key} className="icons">
                {iconElement}
              </Typography>
            ) : null;
          })}
        </Box>
      )}

      <img
        src={props.error ? errorImage : selectedImage}
        onError={(e) => {
          e.target.src = placeHolder;
        }}
        alt="Selected Product"
        className="selected-product"
      />
      <Stack className={classes.customStack}>
        {!props.error &&
          images?.length > 1 &&
          images.map((image, index) => (
            <img
              key={index}
              src={image}
              className="detail-imgs"
              alt="Mini Product"
              onError={(e) => {
                e.target.src = placeHolder;
              }}
              onClick={() => setSelectedImage(image)}
            />
          ))}
      </Stack>
    </Stack>
  );
}
