export const millProducts = [
  "GR",
  "SD",
  "SR",
  "SDC",
  "SDB",
  "SDW",
  "FLX",
  "SRB",
  "ALL",
  "FS",
  "BUL",
  "DSK",
  "ML",
  "AE",
  "RW",
  "SRW",
  "LW",
  "SWF",
  "SWH",
  "SHR",
  "LDW",
  "SWS",
  "SWO",
  "SWE",
  "STW",
  "DHR",
  "SWT",
  "SWP",
  "CHW",
  "BZS",
  "BZW",
  "BSC",
  "BSR",
  "SWD",
  "SWR",
  "WSQ",
  "CZY",
  "TBL",
  "TBM",
  "TBX",
  "TSL",
  "TSM",
  "TSH",
  "TSX",
  "TBH",
];

export function isBand(styleCode) {
  const excludedWCodes = new Set([
    "WAS",
    "WRB",
    "WSQ",
    "WB93",
    "WBR5",
    "WBR26A",
    "WBR85",
    "WBRR56",
    "WBR148",
    "WBRR38",
    "WBMW1",
    "WBMW12",
    "WBMW15",
    "WBMW16",
    "WBMW17",
    "WBMW2",
    "WBMS1",
    "WB3RR08",
    "WBSR5/GHSI",
    "WB3SS11",
    "WB5S90/HI1",
    "WBRR56",
    "WBRR49/GHSI",
    "WBRR38",
    "WBRR38/HI1",
    "WBSR8/GHSI",
    "WBR171/HI1",
    "WBR73A",
    "WBR73",
    "WB273A",
    "WBR273A",
    "WB5S90",
  ]);

  const excludedACodes = new Set([
    "ALL",
    "AE",
    "ABF05",
    "ALB2",
    "ALI1",
    "ALB1",
    "ABRP7",
  ]);

  if (styleCode.startsWith("W")) {
    return !excludedWCodes.has(styleCode);
  }

  if (styleCode.startsWith("A")) {
    return !excludedACodes.has(styleCode);
  }

  return false;
}

export default Object.freeze({
  millProducts,
  isBand,
});
