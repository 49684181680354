import React, { useState, useContext, useEffect, useRef } from "react";
import {
  styled,
  ButtonGroup,
  Typography,
  Grid,
  ButtonBase,
} from "@mui/material";
import { ProductLandingContext } from "../../../utils/stateHandlers/contexts";
import CastImage from "../../../assets/images/icons/filter_icons/icon_Cast.png";
import DieStruckImage from "../../../assets/images/icons/filter_icons/icon_manufacturing_Diestruck.png";
import OpenEndsImage from "../../../assets/images/icons/filter_icons/icon_endtype_OpenEnds.png";
import ClosedEndsImage from "../../../assets/images/icons/filter_icons/icon_endtype_ClosedEnds.png";
import RoundDrillsImage from "../../../assets/images/icons/filter_icons/icon_drills_round.png";
import NoDrillsImage from "../../../assets/images/icons/filter_icons/icon_drills_undrilled.png";
import AzuresImage from "../../../assets/images/icons/filter_icons/icon_drills_azure.png";
import oneRowImage from "../../../assets/images/icons/filter_icons/icon_rowcount_1row.png";
import twoRowsImage from "../../../assets/images/icons/filter_icons/icon_rowcount_2rows.png";
import threeRowsImage from "../../../assets/images/icons/filter_icons/icon_rowcount_3rows.png";
import HammeredImage from "../../../assets/images/icons/filter_icons/icon_bandStyle_Hammered.png";
import ScallopedImage from "../../../assets/images/icons/filter_icons/icon_finishingtype_Scalloped.png";
import MilgrainImage from "../../../assets/images/icons/filter_icons/icon_finishingtype_Milgrain.png";
import GroovedImage from "../../../assets/images/icons/filter_icons/icon_finishingtype_Grooved.png";
import MokumeGaneImage from "../../../assets/images/icons/filter_icons/icon_finishingtype_MokumeGane.png";
import BrushedSatinFinishImage from "../../../assets/images/icons/filter_icons/icon_finishingtype_BrushSatin.png";
import BrightFinishImage from "../../../assets/images/icons/filter_icons/icon_finishingtype_Bright.png";
import PatternedImage from "../../../assets/images/icons/filter_icons/icon_finishingtype_Patterned.png";
import EZfitregImage from "../../../assets/images/icons/filter_icons/icon_fit_EZfit.png";
import StandardImage from "../../../assets/images/icons/filter_icons/icon_fit_Standard.png";
import SoftEZfitregImage from "../../../assets/images/icons/filter_icons/icon_fit_Standard.png";
import CanbemadeintoEngagementRingImage from "../../../assets/images/icons/filter_icons/icon_other_OptionalEngagement.png";
import LightWeightImage from "../../../assets/images/icons/filter_icons/icon_weight_Light.png";
import MediumWeightImage from "../../../assets/images/icons/filter_icons/icon_weight_Medium.png";
import HeavyWeightImage from "../../../assets/images/icons/filter_icons/icon_weight_Heavy.png";
import BridalSetImage from "../../../assets/images/icons/filter_icons/icon_other_BridalSet.png";
import SquareClosedEndsImage from "../../../assets/images/icons/filter_icons/icon_endtype_SquareClosedEnds.png";
import SquareOpenEndsImage from "../../../assets/images/icons/filter_icons/icon_endtype_SquareOpenEnds.png";
import EternityImage from "../../../assets/images/icons/filter_icons/icon_endtype_Eternity.png";
import FlushEndImage from "../../../assets/images/icons/filter_icons/icon_endtype_FlushEnd.png";
import BarEndImage from "../../../assets/images/icons/filter_icons/icon_endtype_BarEnd.png";
import ClosedEndVCutImage from "../../../assets/images/icons/filter_icons/icon_endtype_ClosedEndVCut.png";
const ImageButton = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
  border: "2px solid",
  borderRadius: "10px",
  borderColor: theme.palette.divider,
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    borderRadius: "10px",
  },
}));

const imageMap = {
  // Fit Type
  EZfitregImage,
  StandardImage,
  SoftEZfitregImage,
  // Weight
  LightWeightImage,
  MediumWeightImage,
  HeavyWeightImage,
  // Finishing Type
  HammeredImage,
  ScallopedImage,
  MilgrainImage,
  GroovedImage,
  MokumeGaneImage,
  BrushedSatinFinishImage,
  BrightFinishImage,
  PatternedImage,
  //  MFG
  CastImage,
  DieStruckImage,
  // End Type
  OpenEndsImage,
  ClosedEndsImage,
  SquareClosedEndsImage,
  SquareOpenEndsImage,
  EternityImage,
  FlushEndImage,
  BarEndImage,
  ClosedEndVCutImage,
  // Drills
  RoundDrillsImage,
  NoDrillsImage,
  AzuresImage,
  // Stones Row Count
  oneRowImage,
  twoRowsImage,
  threeRowsImage,
  // Advanced Options
  CanbemadeintoEngagementRingImage,
  BridalSetImage,
};
function ToggleSelectField({
  name,
  label,
  value,
  updateFilters,
  options,
  imageUrl,
}) {
  const { landingState } = useContext(ProductLandingContext);
  const { searchQuery, clearAll } = landingState;
  const toggleField = searchQuery.fields.find((field) => field.name === name);
  const defaultSelectedValues = toggleField?.values || [];
  const [selectedValues, setSelectedValues] = useState(defaultSelectedValues);
  const hasMounted = useRef(false);
  const handleToggleChange = (value) => {
    setSelectedValues((prev) =>
      prev.includes(value) ? prev.filter((v) => v !== value) : [...prev, value]
    );
    updateFilters(name, selectedValues);
  };
  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    updateFilters(name, selectedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  useEffect(() => {
    if (clearAll) {
      setSelectedValues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearAll]);
  return (
    <>
      {options.length !== 0 && (
        <>
          <Typography variant="h6" fontSize="12px" textTransform={"uppercase"}>
            {label}
          </Typography>
          <ButtonGroup value={value}>
            <Grid
              container
              spacing={1}
              sx={{ minWidth: "260px", maxWidth: "260px" }}
            >
              {options.map((option) => (
                <Grid item xs={4} key={option.value}>
                  <ImageButton
                    value={option.value}
                    onClick={() => handleToggleChange(option.value)}
                    sx={{
                      backgroundColor: selectedValues.includes(option.value)
                        ? "rgba(18, 71, 96, 0.08)"
                        : "transparent",
                      color: selectedValues.includes(option.value)
                        ? "white"
                        : "inherit",
                      border: selectedValues.includes(option.value)
                        ? "2px solid skyblue"
                        : "2px solid lightgray",
                      borderRadius: "10px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={imageMap?.[option.image] || imageUrl}
                      alt={option.label}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </ImageButton>
                </Grid>
              ))}
            </Grid>
          </ButtonGroup>
        </>
      )}
    </>
  );
}

export default ToggleSelectField;
