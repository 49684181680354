import axios from "axios";
import { storage } from "./";

export const LOCAL_STORAGE_KEY_AUTH_TOKEN = "authToken";
export const LOCAL_STORAGE_KEY_CART_ID = "cartId";

const begUrl = process.env.REACT_APP_API_URL;
const strapiUrl = "https://cms.hooverandstrong.com/api";
const strapiKey = process.env.REACT_APP_STRAPI_AUTH;

function userToken() {
  return storage.getStorageItem(LOCAL_STORAGE_KEY_AUTH_TOKEN);
}

function commonHeaders() {
  const token = userToken();
  if (token) {
    return {
      Accept: "application/json",
      Authorization: token,
    };
  } else {
    return {
      Accept: "application/json",
    };
  }
}

function strapiHeaders() {
  return {
    Accept: "application/json",
    Authorization: `bearer ${strapiKey}`,
    "Content-Type": "application/json",
  };
}

function bandsHeaders() {
  const token = userToken();
  return {
    Accept: "application/x-www-form-urlencoded",
    Authorization: token,
    "Content-Type": "application/x-www-form-urlencoded",
  };
}

function uploadHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
}

const headers = () =>
  Object.assign(commonHeaders(), { "Content-Type": "application/json" });

const isPrivilegedRoute = (url) => {
  return url.match(/\/api\/history/);
};
axios.interceptors.response.use(
  function (response) {
    //once we get versioning and automation going this will be needed: (DO NOT DELETE)
    // const clientVersion = storage.getStorageItem("app-version");
    // const serverVersion = response.headers["server-version"];
    // if (clientVersion === null || clientVersion !== serverVersion) {
    //   if (clientVersion !== undefined && serverVersion !== undefined) {
    //     storage.setStorageItem("app-version", serverVersion);
    //     console.warn("This page is about to reload with an updated version.")
    //     setTimeout(function () {
    //       window.location.reload(true);
    //     }, 300);
    //   }
    // }
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      if (
        window.location.pathname === "/login" ||
        isPrivilegedRoute(error.request.responseURL)
      ) {
        return error;
      } else {
        storage.removeStorageItem("authToken");
        storage.removeStorageItem("currentUser");
        storage.removeStorageItem("sessionExpiry");
        storage.clearSessionStorage();
        // might need to comment this back out - test in QA
        setTimeout(function () {
          window.location.reload(true);
        }, 300);
      }
    }
    return error;
  }
);

const post = (url, data) => {
  return axios({
    method: "post",
    url: `${begUrl}${url}`,
    data: data,
    timeout: 600000,
    headers: headers(),
  });
};

const get = (url, id) => {
  if (id) {
    return axios.get(`${begUrl}${url}${id}`, {
      headers: headers(),
    });
  } else {
    return axios.get(`${begUrl}${url}`, {
      headers: headers(),
    });
  }
};

const getBandPrice = (url, data) => {
  return axios({
    method: "post",
    url: `${begUrl}${url}`,
    data: data,
    timeout: 600000,
    headers: bandsHeaders(),
  });
};

const addBandToCart = (url, data) => {
  return axios({
    method: "post",
    url: `${begUrl}${url}`,
    data: data,
    timeout: 600000,
    headers: bandsHeaders(),
  });
};

const getStrapi = (url, id) => {
  if (id) {
    return axios.get(`${strapiUrl}${url}${id}`, {
      headers: strapiHeaders(),
    });
  } else {
    return axios.get(`${strapiUrl}${url}`, {
      headers: strapiHeaders(),
    });
  }
};

const pdfDownload = (url, data) => {
  return axios({
    method: "post",
    url: `${begUrl}${url}`,
    data: data,
    timeout: 600000,
    headers: headers(),
    responseType: "blob",
  });
};

const invoiceDownload = (url) => {
  return axios({
    method: "get",
    url: `${begUrl}${url}`,
    timeout: 600000,
    headers: headers(),
    responseType: "blob",
  });
};

const upload = (url, data) => {
  return axios({
    method: "post",
    url: `${begUrl}${url}`,
    data: data,
    timeout: 600000,
    headers: uploadHeaders(),
  });
};

const getEasyPost = (data) => {
  return axios({
    method: "post",
    url: "https://goldfinger.hooverandstrong.com/api/shipping/create-shipment",
    data: data,
    headers: { "Content-Type": "application/json" },
  });
};
const api = {
  getEasyPost: getEasyPost,
  userToken: userToken,
  fetch: get,
  post: post,
  fetchBandPrice: getBandPrice,
  addBandToCart: addBandToCart,
  fetchStrapi: getStrapi,
  pdfDownload: pdfDownload,
  invoiceDownload: invoiceDownload,
  upload: upload,
};

export default api;
