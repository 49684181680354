// Built-in modules
import qs from "qs";

// Third-party libraries
import React, { useContext, useState } from "react";
import { Link as DomLink, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Stack,
  Alert,
  Skeleton,
  Tooltip,
} from "@mui/material";

// Local modules
import { api } from "../../../../utils";
import { storage } from "../../../../utils";
import {
  AppContext,
  BandsContext,
  CartContext,
} from "../../../../utils/stateHandlers/contexts";
import formatting from "../../../../utils/formatHelpers";
import CartModal from "../../../user/CartModal";

const schema = yup.object().shape({
  product: yup.string().required(),
  dimension: yup
    .string()
    .test(
      "dimension-not-selected",
      "Dimension/Size has not been selected.",
      (value) => value !== "None"
    )
    .nullable(),
  quantity: yup.string().required("Quantity is Required"),
  quantity_unit: yup
    .string()
    .test(
      "quantity-unit-not-selected",
      "Quantity Unit has not been selected.",
      (value) => value !== "None"
    )
    .nullable(),
  finger_size: yup
    .string()
    .test(
      "finger_size-not-selected",
      "Ring Size has not been selected.",
      (value) => value !== "None"
    )
    .nullable(),
  width_one: yup
    .string()
    .test(
      "width-not-selected",
      "MM Width has not been selected.",
      (value) => value !== "None"
    )
    .test(
      "width-not-selected",
      "MM Width has not been selected.",
      (value) => value !== ""
    )
    .nullable(),
  width_two: yup
    .string()
    .test(
      "width-not-selected",
      "MM Width has not been selected.",
      (value) => value !== "None"
    )
    .test(
      "width-not-selected",
      "MM Width has not been selected.",
      (value) => value !== ""
    )
    .nullable(),
  width_three: yup
    .string()
    .test(
      "width-not-selected",
      "MM Width has not been selected.",
      (value) => value !== "None"
    )
    .test(
      "width-not-selected",
      "MM Width has not been selected.",
      (value) => value !== ""
    )
    .nullable(),
});

export default function BandsPrice(props) {
  const { bandsState } = useContext(BandsContext);
  const {
    priceBreaks,
    productInfo,
    estimatedShipDate,
    mto,
    message,
    messageType,
    priceLoaded,
    price,
    productDetails,
    validationErrors,
  } = bandsState;
  const { cartDispatch } = useContext(CartContext);
  const { showCustomMetalMarkets, setShowCustomMetalMarkets, authToken } =
    useContext(AppContext);
  const [openCartmodal, setOpenCartModal] = useState(false);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const sendTo = useLocation();

  function findBreakNumber(value) {
    const breakNumber =
      value < 10
        ? 10
        : value < 50
        ? 50
        : value < 100
        ? 100
        : value < 500
        ? 500
        : 0;
    return breakNumber;
  }

  function roundToTenth(number) {
    return Math.round(number * 10) / 10;
  }

  function createPriceBreaksTable(quantity) {
    const dwt = productDetails.find(function (detail) {
      return detail.label === "DWT";
    });
    const oz = productDetails.find(function (detail) {
      return detail.label === "OZ";
    });
    const someMath =
      dwt && props.isMill
        ? findBreakNumber(parseFloat(dwt.value)) - parseFloat(dwt.value)
        : oz && props.isMill
        ? findBreakNumber(parseFloat(oz.value)) - parseFloat(oz.value)
        : findBreakNumber(quantity) - quantity;
    return (
      <>
        {quantity > 0 ? (
          <Typography
            sx={{
              fontFamily: "Verdana",
              textAlign: "center",
              fontSize: "1.4rem",
              fontWeight: "800",
              padding: "1rem",
              marginBottom: ".5rem",
              marginTop: ".5rem",
              backgroundColor: "#cfdde9",
            }}
          >
            Save $$$ - Add {roundToTenth(someMath)} more to reach the next
            quantity price break.
          </Typography>
        ) : (
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Verdana",
              textAlign: "center",
              fontSize: "1.4rem",
              fontWeight: "800",
            }}
          >
            Enter a quantity larger than 0.
          </Typography>
        )}
      </>
    );
  }

  function addToCart() {
    setErrorMessages([]);
    setAddToCartLoading(true);
    cartDispatch({ type: "setCartLoaded", payload: false });
    const formInfo = {
      ...productInfo,
      cart_id:
        storage.getStorageItem("cartId") === "null" ||
        storage.getStorageItem("cartId") === "undefined"
          ? localStorage.removeItem("cartId")
          : storage.getStorageItem("cartId"),
    };
    const addToCartBody = qs.stringify(formInfo);
    schema
      .validate({
        product: formInfo["builder[product]"],
        dimension: formInfo["builder[dimension]"],
        quantity: formInfo["builder[quantity]"],
        quantity_unit: formInfo["builder[quantity_unit]"],
        finger_size: formInfo["complex[finger_size]"],
        width_one: formInfo["complex[1][band][width]"],
        width_two: formInfo["complex[2][band][width]"],
        width_three: formInfo["complex[3][band][width]"],
      })
      .then(() => {
        api
          .addBandToCart("cart/add", addToCartBody)
          .then((res) => {
            if (res.isAxiosError) {
              const errorMessage = [];
              res.response.data.errors.forEach(function (error) {
                errorMessage.push(error);
              });
              toast.error(
                `There was an error processing your request. ${errorMessage.toString()}`
              );
            } else {
              setOpenCartModal(true);
              setAddToCartLoading(false);
              cartDispatch({
                type: "updateCart",
                payload: {
                  cart: res.data.items,
                  cartCount: res.data.count,
                },
              });
              cartDispatch({ type: "setCartLoaded", payload: true });
              storage.setStorageItem("cartId", res.data.cart_id);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      })
      .catch((err) => {
        setAddToCartLoading(false);
        cartDispatch({ type: "setCartLoaded", payload: true });
        setErrorMessages(err.errors);
        setOpenCartModal(true);
      });
  }

  function resetToActualMarkets() {
    api.fetch(`metalbase/reset`).then((res) => {
      setShowCustomMetalMarkets(false);
    });
  }

  return (
    <div>
      <Stack
        spacing={1}
        sx={{ textAlign: "right", marginBottom: "4.5rem", marginTop: "4rem" }}
      >
        <Typography variant="h4" sx={{ textAlign: "right" }}>
          {message ? (
            <Alert variant="filled" severity={messageType}>
              {message}
            </Alert>
          ) : (
            <Stack spacing={1} alignItems="end">
              {priceLoaded && validationErrors.length === 0 && authToken ? (
                price ? (
                  `${formatting["price2"](price)}`
                ) : (
                  "Price currently unavailable"
                )
              ) : validationErrors.length > 0 ? (
                "--------"
              ) : authToken ? (
                <Skeleton
                  variant="rectangular"
                  width="10rem"
                  height="3rem"
                  className="float-right"
                />
              ) : (
                <DomLink
                  to={{
                    pathname: "/login",
                    state: {
                      sendTo: sendTo.pathname,
                      mtoConfiguratorProduct: true,
                    },
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    sx={{ marginTop: "unset" }}
                  >
                    Login For pricing
                  </Button>
                </DomLink>
              )}
              {authToken && (
                <Tooltip
                  title="Prices and weights shown are estimates and subject to change. Most product is sold by weight and billed at daily market prices. Orders will be priced at the metal markets on the date of receipt, if order is shipped in the same day; or the markets of the day after the order is received for out of stock product. Market price is the second London fix plus a surcharge."
                  placement="left"
                >
                  <Typography
                    sx={{ paddingTop: "1rem", textDecoration: "underline" }}
                  >
                    Estimated Price
                  </Typography>
                </Tooltip>
              )}
            </Stack>
          )}
        </Typography>
        {authToken && (
          <>
            {!showCustomMetalMarkets ? (
              <Typography variant="subtitle1">Using Actual Markets</Typography>
            ) : (
              <div>
                <Typography variant="subtitle1">
                  Currently Using Custom Markets
                </Typography>
                <Typography variant="subtitle1">
                  If you place an order, we will NOT hold customized markets
                </Typography>
              </div>
            )}
            {!showCustomMetalMarkets ? (
              <Typography
                onClick={() => setShowCustomMetalMarkets(true)}
                sx={{ color: "#337ab7", textDecoration: "underline" }}
              >
                Set to custom metal markets
              </Typography>
            ) : (
              <Typography
                onClick={() => resetToActualMarkets()}
                sx={{ color: "#337ab7", textDecoration: "underline" }}
              >
                Reset to daily metal markets
              </Typography>
            )}
          </>
        )}
      </Stack>
      {authToken && (
        <Stack spacing={1} sx={{ textAlign: "right" }}>
          <Typography>{mto ? "MADE TO ORDER" : ""}</Typography>
          <Typography>
            Estimated ship date <strong>{estimatedShipDate}</strong> if ordered
            by 5:00pm EST
          </Typography>
          <LoadingButton
            variant="contained"
            color="success"
            sx={{ width: "100%" }}
            onClick={() => addToCart()}
            loading={!priceLoaded || addToCartLoading}
            loadingIndicator={
              addToCartLoading ? "Adding to Cart..." : "Loading Price..."
            }
          >
            Add to Cart
          </LoadingButton>
        </Stack>
      )}
      {priceBreaks.length > 0 && authToken && (
        <>
          <Stack spacing={1}>
            <div>{createPriceBreaksTable(productInfo.quantity)}</div>
          </Stack>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography sx={{ fontWeight: "800" }}>Quantity</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: "800" }}>
                    Price Per Unit
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {priceBreaks &&
                priceBreaks.map((pb) => (
                  <TableRow key={pb.label}>
                    <TableCell>
                      <Typography>{pb.label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {priceLoaded ? (
                          `${formatting["price2"](pb.total)}`
                        ) : (
                          <Skeleton
                            variant="text"
                            width="10rem"
                            className="float-right"
                          />
                        )}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </>
      )}
      <CartModal
        errors={errorMessages}
        open={openCartmodal}
        close={setOpenCartModal}
        product={true}
      />
    </div>
  );
}
