// Third-party libraries
import React, { useReducer, useEffect, useState } from "react";
import { Link as DomLink, useLocation } from "react-router-dom";

import {
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  Tab,
  Backdrop,
  CircularProgress,
  Skeleton,
  MenuItem,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
  Select as MUISelect,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CertDiamondAddToCartPreview from "./CertDiamondAddToCartPreview";
import CertDiamondSingleAddToCartPreview from "./CertDiamondSingleAddToCartPreview";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
// Local modules
import { diamondReducer } from "../../utils/stateHandlers/reducers";
import {
  hiddenColumns,
  hiddenMobile,
  hiddenTablet,
  hiddenForSidePanel,
  showFancyColorColumns,
  hiddenForFcSidePanel,
} from "../../utils/constants";
import { storage } from "../../utils";
import api from "../../utils/api";
import CertDiamondTable from "./CertDiamondTable";
import CertDiamondFilters from "./CertDiamondFilters";
import states from "../../utils/stateHandlers/initialStates";
import { DiamondSearchContext } from "../../utils/stateHandlers/contexts";
import SearchResultsPdf from "./SearchResultsPdf";
import useMediaQueryUtils from "../../utils/mediaQueryUtils";

export default function CertDiamondSearchLanding() {
  const isMdScreen = useMediaQueryUtils("md");
  const initialState = states.initialDiamondSearchState;
  const [diamondState, dispatch] = useReducer(diamondReducer, initialState);
  const {
    currentTab,
    diamonds,
    diamondCount,
    recentlyViewed,
    selected,
    searchQuery,
    pageLoaded,
    resultsLoaded,
    openCartPreview,
    openCartPreviewSingle,
  } = diamondState;
  const loggedIn = storage.getStorageItem("authToken");
  const [view, setView] = useState("list");
  const [imageToggle, setImageToggle] = useState(false);
  const [sortBy, setSortBy] = useState("Carat,desc");
  const sendTo = useLocation();

  const mapResults = (data) => {
    const mappedResults = data.results.map((d) => ({ ...d, Checked: false }));
    data.results = mappedResults;
    dispatch({
      type: "diamonds",
      payload: {
        diamonds: data.results,
        diamondCount: data.count,
      },
    });
  };

  const handleTabChange = (event, newTab) => {
    dispatch({ type: "setTab", payload: newTab });
    dispatch({ type: "setOpenPanel", payload: false });
    dispatch({ type: "setSelectedDiamond", payload: {} });
  };

  const handleSortByChange = (event) => {
    setSortBy(event.target.value);
    const splitValue = event.target.value.split(",");
    const sortField = splitValue[0];
    const sortDirection = splitValue[1];

    dispatch({
      type: "updateSort",
      payload: {
        sortField: sortField,
        sortDirection: sortDirection,
      },
    });
  };

  const Chips = () => (
    <Typography variant="body3" sx={{ marginBottom: "1rem" }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        rowGap={"1rem"}
      >
        {!isMdScreen ? (
          <>
            <Chip
              label={`${selected.length} Selected`}
              sx={{ backgroundColor: "white" }}
              className={`${
                selected && selected.length > 0 ? "" : "invisible"
              }`}
            />
            <Chip
              label="Clear All"
              onClick={clearAllSelected}
              className={`${
                selected && selected.length > 0 ? "" : "invisible"
              }`}
            />

            <Chip
              color="success"
              label="Add to Cart"
              disabled={loggedIn ? false : true}
              className={`${
                selected && selected.length > 0 ? "" : "invisible"
              }`}
              onClick={(e) => {
                dispatch({ type: "setOpenCartPreview", payload: true });
              }}
            />
            <Chip
              label="Compare"
              value="Selected"
              className={`${
                selected && selected.length > 0 ? "" : "invisible"
              }`}
              onClick={(event) => handleTabChange(event, "Selected")}
            />
          </>
        ) : null}

        {loggedIn &&
          resultsLoaded &&
          (!isMdScreen ? <SearchResultsPdf /> : null)}

        <Stack
          direction="row"
          alignItems="center"
          className="float-right"
          spacing={3}
          sx={{ marginTop: "1rem" }}
        >
          {searchQuery.page > 1 && (
            <ChevronLeftIcon onClick={() => prevPage()} className="pointer" />
          )}
          <Typography variant="body3">Page {searchQuery.page}</Typography>
          {diamondCount > searchQuery.page * searchQuery.size && (
            <ChevronRightIcon onClick={() => nextPage()} className="pointer" />
          )}
          <Divider orientation="vertical" flexItem />
          <Typography variant="body3">Results Per Page: </Typography>
          <MUISelect
            size="small"
            value={searchQuery.size}
            onChange={(event) =>
              dispatch({
                type: "updateSize",
                payload: event.target.value,
              })
            }
          >
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </MUISelect>
        </Stack>
        {view === "grid" ? (
          <FormControl>
            <InputLabel id="sort-by-label">Sort by</InputLabel>
            <MUISelect
              size="small"
              id="sort-by-select"
              labelId="sort-by-label"
              label="Sort by"
              value={sortBy}
              onChange={handleSortByChange}
            >
              <MenuItem value={"Carat,asc"}>Carat Size: Low to High</MenuItem>
              <MenuItem value={"Carat,desc"}>Carat Size: High to Low</MenuItem>
              <MenuItem value={"HooverPrice,asc"}>Price: Low to High</MenuItem>
              <MenuItem value={"HooverPrice,desc"}>Price: High to Low</MenuItem>
            </MUISelect>
          </FormControl>
        ) : null}
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={toggleView}
          size="small"
          sx={{
            "& .MuiToggleButton-root": {
              borderRadius: 0,
              height: "3.7rem",
            },
          }}
        >
          <ToggleButton value="list">
            <ViewListIcon />
          </ToggleButton>
          <ToggleButton value="grid">
            <GridViewIcon />
          </ToggleButton>
        </ToggleButtonGroup>
        {!isMdScreen ? (
          <Chip
            color="warning"
            label="Reset Filters"
            onClick={() => resetFilters()}
          />
        ) : null}

        {!loggedIn && (
          <DomLink
            to={{
              pathname: "/login",
              state: { sendTo: sendTo.pathname },
            }}
          >
            {!isMdScreen ? (
              <Button
                variant="contained"
                color="error"
                size="small"
                sx={{ marginTop: "unset" }}
              >
                Login For Pricing
              </Button>
            ) : null}
          </DomLink>
        )}
      </Stack>
    </Typography>
  );

  function clearAllSelected() {
    dispatch({ type: "setSelected", payload: [] });
    storage.setSessionStorageItem("hsSelectedData", JSON.stringify([]));
    diamonds.forEach((d) => {
      if (d.Checked) {
        d.Checked = false;
      }
    });
  }

  const toggleView = (event, selectedView) => {
    setView(selectedView);
    let fields = searchQuery.fields;
    if (selectedView === "grid") {
      fields = searchQuery.fields.concat({
        name: "ImageVideoAvailable",
        values: [{ name: "Y" }],
      });
      setImageToggle(true);
    } else {
      fields = fields.filter((field) => field.name !== "ImageVideoAvailable");
      setImageToggle(false);
    }
    dispatch({ type: "updateSearchQuery", payload: fields });
  };

  function resetFilters() {
    const minedOrLab = searchQuery.fields.find(
      (field) => field.name === "MinedOrLab"
    ).values[0].name;

    if (minedOrLab === "L") {
      dispatch({
        type: "clearFilters",
        payload: [
          {
            name: "MinedOrLab",
            values: [{ name: "L" }],
          },
        ],
      });
    } else {
      dispatch({
        type: "clearFilters",
        payload: [
          {
            name: "MinedOrLab",
            values: [{ name: "M" }],
          },
        ],
      });
    }
  }

  function nextPage() {
    const currentPage = searchQuery.page;
    dispatch({ type: "updatePage", payload: currentPage + 1 });
  }

  function prevPage() {
    const currentPage = searchQuery.page;
    dispatch({ type: "updatePage", payload: currentPage - 1 });
  }

  function formatPriceFieldsForNoAuth(field) {
    const newField = {
      name: field.name,
      search_order: field.name === "HooverPrice" ? 20 : null,
      results_order:
        field.name === "HooverPrice"
          ? 190
          : field.name === "CalcPricePerCarat"
          ? 180
          : null,
      label:
        field.name === "HooverPrice"
          ? "Price"
          : field.name === "CalcPricePerCarat"
          ? "Price P/C"
          : "",
    };
    return newField;
  }

  useEffect(() => {
    api.fetch("diamonds/fields").then((response) => {
      const standard = response.data.filter(function (field) {
        return (
          !field.advanced &&
          field.search_order &&
          field.name !== "AsGrown" &&
          field.name !== "ScsGrower" &&
          field.name !== "ShipsSameDay" &&
          field.name !== "CalcPricePerCarat"
        );
      });
      const priceFilter = response.data.find(function (field) {
        return field.name === "HooverPrice";
      });
      const formatPriceFilter = formatPriceFieldsForNoAuth(priceFilter);
      const sortedFilters = [...standard, formatPriceFilter]
        .filter(
          (field, index, self) =>
            self.findIndex((f) => f.name === field.name) === index
        )
        .sort((a, b) => a.search_order - b.search_order);
      const advanced = response.data.filter(function (field) {
        return (
          field.advanced === true &&
          field.name !== "AsGrown" &&
          field.name !== "ScsGrower" &&
          field.name !== "ShipsSameDay" &&
          field.name !== "CalcPricePerCarat" &&
          field.name !== "FancyColor" &&
          field.name !== "FancyColorIntensity" &&
          field.name !== "FancyColorOvertone"
        );
      });
      const fancyColorFilters = response.data.filter(function (field) {
        return (
          field.advanced === true &&
          (field.name === "FancyColor" ||
            field.name === "FancyColorIntensity" ||
            field.name === "FancyColorOvertone")
        );
      });
      const headers = response.data.filter(function (field) {
        return field.results_order && !hiddenColumns.includes(field.name);
      });
      const fancyColorHeaders = response.data.filter(function (field) {
        return (
          field.results_order && !showFancyColorColumns.includes(field.name)
        );
      });
      const sortedFcHeaders = fancyColorHeaders.sort(
        (a, b) => a.results_order - b.results_order
      );
      const pricePerCaratField = response.data.find(function (field) {
        return formatPriceFieldsForNoAuth(field.name === "CalcPricePerCarat");
      });
      const formatPerCaratField =
        formatPriceFieldsForNoAuth(pricePerCaratField);
      const allHeaders = [
        ...headers,
        formatPriceFilter,
        formatPerCaratField,
      ].filter(
        (field, index, self) =>
          self.findIndex((f) => f.name === field.name) === index
      );
      const sortedHeaders = allHeaders.sort(
        (a, b) => a.results_order - b.results_order
      );
      const sidePanelHeaders = sortedHeaders.filter(function (header) {
        return !hiddenForSidePanel.includes(header.name);
      });
      const fcSidePanelHeaders = sortedFcHeaders.filter(function (header) {
        return !hiddenForFcSidePanel.includes(header.name);
      });
      const mobileHeaders = sortedHeaders.filter(function (header) {
        return !hiddenMobile.includes(header.name);
      });
      const tabletHeaders = sortedHeaders.filter(function (header) {
        return !hiddenTablet.includes(header.name);
      });
      const fancyColorMobileHeaders = sortedFcHeaders.filter(function (header) {
        return !hiddenMobile.includes(header.name);
      });
      dispatch({
        type: "setFilters",
        payload: {
          filters: sortedFilters,
          advancedFilters: advanced,
          fancyColorFilters: fancyColorFilters,
          tableHeaders: sortedHeaders,
          fancyColorHeaders: sortedFcHeaders,
          mobileTableHeaders: mobileHeaders,
          fancyColorMobileHeaders: fancyColorMobileHeaders,
          tabletTableHeaders: tabletHeaders,
          sidePanelTableHeaders: sidePanelHeaders,
          fcSidePanelHeaders: fcSidePanelHeaders,
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: "resultsLoaded", payload: false });
    api
      .post(`diamonds/search`, {
        fields: searchQuery.fields,
        page: searchQuery.page,
        size: searchQuery.size,
        sort: searchQuery.sort,
      })
      .then((res) => {
        mapResults(res.data);
        dispatch({ type: "resultsLoaded", payload: true });
        dispatch({ type: "pageLoaded", payload: true });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  useEffect(() => {
    const rvStorage = JSON.parse(
      storage.getSessionStorageItem("hsRecentlyViewedData")
    );
    const selectedStorage = JSON.parse(
      storage.getSessionStorageItem("hsSelectedData")
    );
    if (rvStorage && rvStorage.length > 0) {
      dispatch({ type: "setRecentlyViewed", payload: rvStorage });
    }
    if (selectedStorage && selectedStorage.length > 0) {
      dispatch({ type: "setSelected", payload: selectedStorage });
      //loop through search results and check selected
      //add if statement here to check if the diamond is still available for sale
      selectedStorage.forEach((s) => {
        diamonds.forEach((r) => {
          if (s.HooverStockId === r.HooverStockId) {
            r.Checked = true;
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diamonds]);

  useEffect(() => {
    if (recentlyViewed.length > 0) {
      dispatch({ type: "setRecentlyViewed", payload: recentlyViewed });
      storage.setSessionStorageItem(
        "hsRecentlyViewedData",
        JSON.stringify(recentlyViewed)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentlyViewed]);

  useEffect(() => {
    if (selected.length > 0) {
      dispatch({ type: "setSelected", payload: selected });
      dispatch({ type: "setCartPreview", payload: selected });
      storage.setSessionStorageItem("hsSelectedData", JSON.stringify(selected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <DiamondSearchContext.Provider value={{ diamondState, dispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Stack>
            <Grid container direction="row" justifyContent="center">
              <Typography
                variant="h1"
                sx={{
                  marginTop: "3rem",
                  marginRight: "unset",
                  marginBottom: "2rem",
                  textAlign: "center",
                }}
              >
                Certified Diamond Search
              </Typography>
            </Grid>
            <CertDiamondFilters
              setImageToggle={setImageToggle}
              imageToggle={imageToggle}
              setView={setView}
              toggleView={toggleView}
            />
            <TabContext value={currentTab}>
              <Grid
                container
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Grid
                  item
                  xs={12}
                  s={6}
                  spacing={3}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    flexWrap: "wrap",
                  }}
                >
                  {currentTab === "Results" && <>{Chips()}</>}
                </Grid>
                <Grid
                  item
                  xs={12}
                  s={6}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TabList onChange={handleTabChange} textColor="inherit">
                    <Tab
                      label={`Results (${
                        diamondCount > 8000 ? "8000+" : diamondCount
                      })`}
                      value="Results"
                      className="font-size-regular"
                    />
                    <Tab
                      label={`Selected (${
                        selected.filter((x) => x > "").length
                      })`}
                      value="Selected"
                      className="font-size-regular"
                    />
                    {!isMdScreen ? (
                      <Tab
                        label={`Recently Viewed (${
                          recentlyViewed.filter((x) => x > "").length
                        })`}
                        value="RecentlyViewed"
                        className="font-size-regular"
                      />
                    ) : (
                      <Chip
                        color="warning"
                        label="Reset Filters"
                        onClick={() => resetFilters()}
                      />
                    )}
                  </TabList>
                </Grid>
              </Grid>
              <TabPanel value="Results">
                {!resultsLoaded ? (
                  <Typography variant="h1" sx={{ width: "100%" }}>
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                    <Skeleton animation="wave" />
                  </Typography>
                ) : (
                  <CertDiamondTable
                    diamonds={diamonds}
                    loggedIn={loggedIn}
                    view={view}
                  />
                )}
                <Stack
                  direction="row"
                  alignItems="center"
                  className="float-right"
                  spacing={3}
                  sx={{ marginTop: "1rem" }}
                >
                  {searchQuery.page > 1 && (
                    <ChevronLeftIcon
                      onClick={() => prevPage()}
                      className="pointer"
                    />
                  )}
                  <Typography variant="body3">
                    Page {searchQuery.page}
                  </Typography>
                  {diamondCount > searchQuery.page * searchQuery.size && (
                    <ChevronRightIcon
                      onClick={() => nextPage()}
                      className="pointer"
                    />
                  )}
                  <Divider orientation="vertical" flexItem />
                  <Typography variant="body3">Results Per Page: </Typography>
                  <MUISelect
                    size="small"
                    value={searchQuery.size}
                    onChange={(event) =>
                      dispatch({
                        type: "updateSize",
                        payload: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </MUISelect>
                </Stack>
              </TabPanel>
              <TabPanel value="RecentlyViewed">
                <CertDiamondTable
                  diamonds={recentlyViewed}
                  loggedIn={loggedIn}
                  view={view}
                />
              </TabPanel>
              <TabPanel value="Selected">
                {selected && (
                  <CertDiamondTable
                    diamonds={selected}
                    loggedIn={loggedIn}
                    view={view}
                  />
                )}
              </TabPanel>
            </TabContext>
          </Stack>
        </>
      )}
      {openCartPreview && <CertDiamondAddToCartPreview />}
      {openCartPreviewSingle && <CertDiamondSingleAddToCartPreview />}
    </DiamondSearchContext.Provider>
  );
}
