import {
  Bestseller,
  Cast,
  Diestruck,
  New,
  Truseat,
  Polished,
  EZFit,
  MetalMold,
  NonHarmony,
  GetItFast,
  Harmony,
} from "../components/Icons";
import storage from "./storageFactory";
import React from "react";
import Asscher from "../assets/images/shapes/asscher.svg";
import Cushion from "../assets/images/shapes/cushion.svg";
import Emerald from "../assets/images/shapes/emerald.svg";
import Heart from "../assets/images/shapes/heart.svg";
import Marquise from "../assets/images/shapes/marquise.svg";
import Oval from "../assets/images/shapes/oval.svg";
import Pear from "../assets/images/shapes/pear.svg";
import Princess from "../assets/images/shapes/princess.svg";
import Radiant from "../assets/images/shapes/radiant.svg";
import Round from "../assets/images/shapes/round.svg";
import AsscherReversed from "../assets/images/shapes/asscher-reversed.svg";
import CushionReversed from "../assets/images/shapes/cushion-reversed.svg";
import EmeraldReversed from "../assets/images/shapes/emerald-reversed.svg";
import HeartReversed from "../assets/images/shapes/heart-reversed.svg";
import MarquiseReversed from "../assets/images/shapes/marquise-reversed.svg";
import OvalReversed from "../assets/images/shapes/oval-reversed.svg";
import PearReversed from "../assets/images/shapes/pear-reversed.svg";
import PrincessReversed from "../assets/images/shapes/princess-reversed.svg";
import RadiantReversed from "../assets/images/shapes/radiant-reversed.svg";
import RoundReversed from "../assets/images/shapes/round-reversed.svg";
import Trillion from "../assets/images/shapes/trillion.svg";
import TrillionReversed from "../assets/images/shapes/trillion-reversed.svg";
import Rose from "../assets/images/shapes/rose.svg";
import RoseReversed from "../assets/images/shapes/rose-reversed.svg";
import StraightBaguette from "../assets/images/shapes/straightbaguette.svg";
import StraightBaguetteReversed from "../assets/images/shapes/straightbaguette-reversed.svg";
import OldEuro from "../assets/images/shapes/oldeuropean-diagram-top.svg";
import OldMiner from "../assets/images/shapes/oldmine-diagram-top.svg";

function storeRecentlyViewed(style) {
  const previous = JSON.parse(
    storage.getSessionStorageItem("hsRecentlyViewedProducts")
  );
  if (previous !== null) {
    const viewedArray = [...previous, style];
    const uniqueViewArray = viewedArray.filter((item, index, array) => {
      return index === array.findIndex((obj) => obj.style === item.style);
    });
    storage.setSessionStorageItem(
      "hsRecentlyViewedProducts",
      JSON.stringify(uniqueViewArray)
    );
  } else if (previous === null) {
    storage.setSessionStorageItem(
      "hsRecentlyViewedProducts",
      JSON.stringify([style])
    );
  }
}

function getIcons(icons) {
  switch (icons) {
    case "DIESTRCK":
      return <Diestruck />;
    case "BEST":
      return <Bestseller />;
    case "CAST":
      return <Cast />;
    case "NEW":
      return <New />;
    case "TRUSEAT":
      return <Truseat />;
    case "POLISHED":
      return <Polished />;
    case "EZFIT":
      return <EZFit />;
    case "METALMLD":
      return <MetalMold />;
    case "NONHARM":
      return <NonHarmony />;
    case "GETITFAST":
      return <GetItFast />;
    case "HARMONY":
      return <Harmony />;
    default:
      break;
  }
}

function getDiamondShape(shape) {
  switch (shape) {
    case "Asscher":
      return <img src={Asscher} alt={shape} className="diamond-image" />;
    case "AsscherReversed":
      return <img src={AsscherReversed} alt={shape} />;
    case "Cushion":
      return <img src={Cushion} alt={shape} className="diamond-image" />;
    case "CushionReversed":
      return <img src={CushionReversed} alt={shape} />;
    case "Emerald":
      return <img src={Emerald} alt={shape} className="diamond-image" />;
    case "EmeraldReversed":
      return <img src={EmeraldReversed} alt={shape} />;
    case "Heart":
      return <img src={Heart} alt={shape} className="diamond-image" />;
    case "HeartReversed":
      return <img src={HeartReversed} alt={shape} />;
    case "Marquise":
      return <img src={Marquise} alt={shape} className="diamond-image" />;
    case "MarquiseReversed":
      return <img src={MarquiseReversed} alt={shape} />;
    case "Oval":
      return <img src={Oval} alt={shape} className="diamond-image" />;
    case "OvalReversed":
      return <img src={OvalReversed} alt={shape} />;
    case "Pear":
      return <img src={Pear} alt={shape} className="diamond-image" />;
    case "PearReversed":
      return <img src={PearReversed} alt={shape} />;
    case "Princess":
      return <img src={Princess} alt={shape} className="diamond-image" />;
    case "PrincessReversed":
      return <img src={PrincessReversed} alt={shape} />;
    case "Radiant":
      return <img src={Radiant} alt={shape} className="diamond-image" />;
    case "RadiantReversed":
      return <img src={RadiantReversed} alt={shape} />;
    case "Round":
      return <img src={Round} alt={shape} className="diamond-image" />;
    case "RoundReversed":
      return <img src={RoundReversed} alt={shape} />;
    case "Trillion":
      return <img src={Trillion} alt={shape} className="diamond-image" />;
    case "TrillionReversed":
      return <img src={TrillionReversed} alt={shape} />;
    case "Old Euro":
      return <img src={OldEuro} alt={shape} className="diamond-image" />;
    case "Old Miner":
      return <img src={OldMiner} alt={shape} className="diamond-image" />;
    case "Rose":
      return <img src={Rose} alt={shape} className="diamond-image" />;
    case "RoseReversed":
      return <img src={RoseReversed} alt={shape} />;
    case "Straight Baguette":
      return (
        <img src={StraightBaguette} alt={shape} className="diamond-image" />
      );
    case "BaguetteReversed":
      return <img src={StraightBaguetteReversed} alt={shape} />;
    default:
      break;
  }
}

const functions = {
  storeRecentlyViewed: storeRecentlyViewed,
  getIcons: getIcons,
  getDiamondShape: getDiamondShape,
};

export default functions;
