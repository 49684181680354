// Third-party libraries
import React from "react";
import { useParams } from "react-router-dom";

// Local modules
import { storage } from "../../utils";
import styleCodes, { isBand } from "../../utils/styleCodes";
import Bands from "../products/productDetails/Bands";
import ProductDetailPage from "../products/productDetails/ProductDetailPage";
import Charms from "../products/productDetails/Charms";
import CoinsBullion from "../products/productDetails/CoinsBullions";

// This component is a wrapper around the product detail page.
// It renders other components based on the product style code.
function extractStyles(style) {
  const styles = style.split("/").slice(2, style.length);
  return styles.join("/");
}
export default function Product(props) {
  const { ProductStyleCode } = useParams();
  const RecentlyViewed = JSON.parse(
    storage.getSessionStorageItem("hsRecentlyViewedProducts")
  );
  const productStyles = extractStyles(props.location.pathname);
  const isMill = () => {
    return styleCodes.millProducts.includes(ProductStyleCode);
  };
  const band = isBand(ProductStyleCode);
  const jewelryProductGroup =
    ProductStyleCode === "CIRCLES"
      ? "Circles"
      : ProductStyleCode === "WRB"
      ? "Ring Blanks"
      : ProductStyleCode === "WAS"
      ? "Washers"
      : ProductStyleCode === "CHM"
      ? "Charms"
      : props.location.state
      ? props.location.state.productGroup
      : "Jewelry";
  return band === true ? (
    <Bands
      ProductStyleCode={ProductStyleCode}
      ProductGroup="Bands"
      isMill={false}
      RecentlyViewed={RecentlyViewed}
      {...props}
    />
  ) : ProductStyleCode === "CHM" ? (
    <Charms
      ProductStyleCode={ProductStyleCode}
      ProductGroup="Charms"
      isMill={false}
      RecentlyViewed={RecentlyViewed}
      {...props}
    />
  ) : ProductStyleCode === "COINS BULLION" ? (
    <CoinsBullion
      ProductStyleCode={ProductStyleCode}
      ProductGroup="Circles"
      RecentlyViewed={RecentlyViewed}
      isMill={false}
      {...props}
    />
  ) : isMill() === true ? (
    [
      "GR",
      "SD",
      "SDC",
      "SR",
      "SRB",
      "FLX",
      "SDB",
      "ALL",
      "FS",
      "BUL",
      "DSK",
      "ML",
      "AE",
      "LW",
      "SWD",
      "SWR",
    ].includes(ProductStyleCode) ? (
      <ProductDetailPage
        ProductStyleCode={ProductStyleCode}
        ProductGroup="Mill"
        isMill={true}
        RecentlyViewed={RecentlyViewed}
        {...props}
      />
    ) : (
      <ProductDetailPage
        ProductStyleCode={ProductStyleCode}
        ProductGroup="WireTubing"
        RecentlyViewed={RecentlyViewed}
        isMill={true}
        {...props}
      />
    )
  ) : (
    <ProductDetailPage
      ProductStyleCode={productStyles}
      ProductGroup={jewelryProductGroup}
      RecentlyViewed={RecentlyViewed}
      isMill={false}
      {...props}
    />
  );
}
