// Third-party libraries
import React, { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Stack,
  Typography,
  Box,
  Chip,
  Collapse,
  Grid,
  CircularProgress,
  Backdrop,
  Divider,
} from "@mui/material";

// Local modules
import { ProductLandingContext } from "../../../utils/stateHandlers/contexts";
import { api } from "../../../utils";
import states from "../../../utils/stateHandlers/initialStates";
import { landingReducer } from "../../../utils/stateHandlers/reducers";
import ProductTiles from "./ProductTiles";
import ProductFilter from "./ProductFilter";
import LoadingIndicator from "./LoadingIndicator";
import useMediaQueryUtils from "../../../utils/mediaQueryUtils";

export default function ProductLandingPage(props) {
  const isMdScreen = useMediaQueryUtils("md");
  const [landingState, landingDispatch] = useReducer(
    landingReducer,
    states.initialProductLandingState
  );
  const location = useLocation();
  const { state } = location || {};
  const {
    pageLoaded,
    itemsLoaded,
    showFilters,
    filters,
    bestSellers,
    products,
    searchQuery,
  } = landingState;
  const [showMoreFilters, setShowMoreFilters] = useState(true);
  const category = props.Category;
  const isMill = props.isMill;

  function getFilterValues(filter, filterGroup) {
    Object.keys(filter).forEach((filterKey) => {
      const group = filterGroup.find((group) => group.name === filterKey);
      if (group) {
        filter[filterKey].forEach((filterItem) => {
          const matchingValue = group.values.find(
            (value) => value.label === filterItem.label
          );
          if (matchingValue) {
            filterItem.value = matchingValue.value;
          }
        });
      }
    });
    return filter;
  }

  function updateFilterValues(newFilters) {
    (newFilters || []).forEach((newFilter) => {
      const oldFilter = filters.find(
        (filter) => filter.name === newFilter.name
      );
      if (oldFilter) {
        oldFilter.values = newFilter.values;
      }
    });
  }

  function filterBestSellers() {
    const bsFilter = searchQuery.fields.find(
      (searchField) => searchField.name === "BestSeller"
    );

    const bsFilterField = filters.find(
      (filter) => filter.name === "BestSeller"
    );

    if (bsFilter) {
      if (bsFilter.values.length > 0) {
        bsFilter.values = [];
        landingDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
        landingDispatch({ type: "showBestSellers", payload: false });
      } else {
        bsFilter.values = [bsFilterField.values[0].value];
        landingDispatch({
          type: "updateSearchQuery",
          payload: searchQuery.fields,
        });
        landingDispatch({ type: "showBestSellers", payload: true });
      }
    } else {
      const fields = [
        ...searchQuery.fields,
        {
          name: "BestSeller",
          values: [bsFilterField.values[0].value],
        },
      ];
      landingDispatch({ type: "updateSearchQuery", payload: fields });
      landingDispatch({ type: "showBestSellers", payload: true });
    }
  }

  useEffect(() => {
    landingDispatch({ type: "setPageLoaded", payload: false });
    landingDispatch({ type: "setItemsLoaded", payload: false });
    api
      .post("catalog/items", {
        category: category,
        filters: [],
      })
      .then((response) => {
        SortFilterOrder(response.data.groups);
        AddImageNames(response.data.groups);
        const dataGroups = AddImageNames(response.data.groups);
        landingDispatch({ type: "setFilters", payload: dataGroups });
        landingDispatch({ type: "setProducts", payload: response.data.items });
        if (state !== undefined) {
          getFilterValues(state, dataGroups);
        }
        landingDispatch({ type: "setPageLoaded", payload: true });
        landingDispatch({ type: "setItemsLoaded", payload: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  function SortFilterOrder(groups) {
    if (groups === undefined) {
      return;
    }
    const customOrder = [
      "Category",
      "BandStyle",
      "BandType",
      "StoneShape",
      "Setting",
      "Material",
      "Weight",
      "FitType",
      "FinishingType",
      "MfgMethod",
      "BandEndType",
      "StonesRowCount",
      "Drills",
      "AdvancedOptions",
    ];
    const sortedData = groups.sort(
      (a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name)
    );
    return sortedData;
  }

  function AddImageNames(groups) {
    if (groups === undefined) {
      return;
    }

    const digitToText = {
      0: "zero",
      1: "one",
      2: "two",
      3: "three",
      4: "four",
      5: "five",
      6: "six",
      7: "seven",
      8: "eight",
      9: "nine",
    };

    groups.forEach((item) => {
      if (item.name === "Material") {
        item.values.forEach((valueItem) => {
          const label = valueItem.label || "placeHolder";
          valueItem.image = `${label
            .replace(/[^a-zA-Z0-9]/g, "")
            .replace(/\d+/g, "")
            .replace(/\s+/g, "")}Image`;
        });
      } else {
        item.values.forEach((valueItem) => {
          const label = valueItem.label || "placeHolder";

          const labelWithTextDigits = label.replace(
            /\d/g,
            (digit) => digitToText[digit]
          );

          valueItem.image = `${labelWithTextDigits
            .replace(/[^a-zA-Z0-9]/g, "")
            .replace(/\s+/g, "")}Image`;
        });
      }
    });
    return groups;
  }

  useEffect(() => {
    if (pageLoaded) {
      landingDispatch({ type: "setItemsLoaded", payload: false });
      api
        .post("catalog/items", {
          category: category,
          filters: searchQuery.fields,
        })
        .then((response) => {
          SortFilterOrder(response.data.groups);
          const dataGroups = AddImageNames(response.data.groups);
          updateFilterValues(dataGroups);
          landingDispatch({
            type: "setProducts",
            payload: response.data.items,
          });
          landingDispatch({ type: "setItemsLoaded", payload: true });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  return (
    <ProductLandingContext.Provider value={{ landingState, landingDispatch }}>
      {!pageLoaded ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Stack spacing={1} className="sticky-filters">
            {isMdScreen ? (
              <>
                <Collapse in={showFilters}>
                  {!itemsLoaded && (
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.modal + 2,
                      }}
                      open={true}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  )}
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {bestSellers ? (
                      <Chip
                        color="success"
                        label="Show All"
                        sx={{ marginRight: ".5rem" }}
                        onClick={() => filterBestSellers()}
                      />
                    ) : (
                      <Chip
                        color="primary"
                        variant="outlined"
                        label="Show Only Best Sellers"
                        sx={{ marginRight: ".5rem" }}
                        onClick={() => filterBestSellers()}
                      />
                    )}
                    <Chip
                      color="warning"
                      label="Reset Filters"
                      sx={{ paddingLeft: ".5rem" }}
                      onClick={() => {
                        landingDispatch({
                          type: "updateSearchQuery",
                          payload: [],
                        });
                        landingDispatch({ type: "setClearAll", payload: true });
                      }}
                    />
                  </Stack>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: {
                        sm: "repeat(1, 1fr)",
                        md: "repeat(6, 1fr)",
                        xl: "repeat(6, 1fr)",
                      },
                      overflowY: "auto",
                      margin: "2rem",
                      maxHeight: "500px",
                    }}
                  >
                    {filters &&
                      filters
                        .filter((filter) =>
                          [
                            "Category",
                            "BandStyle",
                            "BandType",
                            "StoneShape",
                            "Setting",
                            "Material",
                            "Weight",
                            "FitType",
                            "FinishingType",
                            "MfgMethod",
                            "BandEndType",
                            "StonesRowCount",
                            "Drills",
                            "AdvancedOptions",
                          ].includes(filter.name)
                        )
                        .map((filter) => (
                          <ProductFilter
                            filter={filter}
                            key={filter.name}
                            showMoreFilters={showMoreFilters}
                            category={category}
                            state={state}
                          />
                        ))}
                  </Box>
                </Collapse>
                <Stack justifyContent="center" alignItems="center">
                  {!showFilters ? (
                    <Chip
                      color="primary"
                      label="Show Filters"
                      icon={<ArrowDropDownIcon />}
                      sx={{
                        paddingLeft: ".5rem",
                        position: "fixed",
                      }}
                      onClick={() => {
                        landingDispatch({
                          type: "setShowFilters",
                          payload: true,
                        });
                        setShowMoreFilters(!showMoreFilters);
                      }}
                    />
                  ) : (
                    <>
                      <Chip
                        color="primary"
                        label="Hide Filters"
                        icon={<ArrowDropUpIcon />}
                        sx={{ paddingLeft: ".5rem" }}
                        onClick={() => {
                          landingDispatch({
                            type: "setShowFilters",
                            payload: false,
                          });
                          setShowMoreFilters(!showMoreFilters);
                        }}
                      />
                      <Divider flexview="true" sx={{ width: "100%" }} />
                    </>
                  )}
                </Stack>
              </>
            ) : (
              <>
                <Grid container sx={{ paddingTop: "3rem" }}>
                  <Grid item xs={6} md={8} lg={10}>
                    <Typography variant="h1">{category}</Typography>
                  </Grid>
                  {!isMill && (
                    <Grid item xs={4} md={2}>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <Chip
                          color="warning"
                          label="Reset Filters"
                          onClick={() => {
                            landingDispatch({
                              type: "updateSearchQuery",
                              payload: [],
                            });
                            landingDispatch({
                              type: "setClearAll",
                              payload: true,
                            });
                          }}
                        />
                        {filters &&
                          filters.map((filter) => (
                            <React.Fragment key={filter.name}>
                              {filter.name === "BestSeller" && (
                                <>
                                  {bestSellers ? (
                                    <Chip
                                      color="success"
                                      label="Show All"
                                      sx={{ marginRight: ".5rem" }}
                                      onClick={() => filterBestSellers()}
                                    />
                                  ) : (
                                    <Chip
                                      color="primary"
                                      variant="outlined"
                                      label="Best Sellers"
                                      sx={{ marginRight: ".5rem" }}
                                      onClick={() => filterBestSellers()}
                                    />
                                  )}
                                </>
                              )}
                            </React.Fragment>
                          ))}
                      </Stack>
                    </Grid>
                  )}
                </Grid>
                {!isMill && category === "All Bands" ? (
                  <>
                    <Grid container direction={"row"} spacing={1}>
                      {!itemsLoaded && (
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.modal + 2,
                          }}
                          open={true}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      )}

                      {filters &&
                        filters
                          .filter((filter) =>
                            [
                              "Category",
                              "BandStyle",
                              "BandType",
                              "StoneShape",
                              "Setting",
                            ].includes(filter.name)
                          )
                          .map((filter) => (
                            <ProductFilter
                              filter={filter}
                              key={filter.name}
                              showMoreFilters={showMoreFilters}
                              category={category}
                              state={state}
                            />
                          ))}

                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          alignItems: "center",

                          justifyContent: "center",
                        }}
                      >
                        {showMoreFilters ? (
                          <Chip
                            color={"primary"}
                            label="Show More Filters"
                            icon={<ArrowDropDownIcon />}
                            onClick={() => setShowMoreFilters(!showMoreFilters)}
                          />
                        ) : (
                          <Chip
                            color={"primary"}
                            label="Hide Filter"
                            icon={<ArrowDropUpIcon />}
                            onClick={() => setShowMoreFilters(!showMoreFilters)}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <>
                        <Grid
                          container
                          maxWidth={"100%"}
                          sx={{
                            overflowY: "auto",
                            maxHeight: "500px",
                            display: !showMoreFilters ? "flex" : "none",
                          }}
                        >
                          <Divider flexview="true" sx={{ width: "100%" }} />
                          <Grid item xs={12} md={12} lg={3} xl={3}>
                            {filters &&
                              filters
                                .filter((filter) =>
                                  ["Material"].includes(filter.name)
                                )
                                .map((filter) => (
                                  <ProductFilter
                                    filter={filter}
                                    key={filter.name}
                                    showMoreFilters={showMoreFilters}
                                    category={category}
                                    state={state}
                                  />
                                ))}
                          </Grid>
                          <Grid item xs={12} md={12} lg={9} xl={9}>
                            <Grid container spacing={2} minWidth={"100%"}>
                              <Grid item xl={2.4}>
                                <Stack direction={"column"}>
                                  {filters &&
                                    filters
                                      .filter((filter) =>
                                        ["Weight", "FitType"].includes(
                                          filter.name
                                        )
                                      )
                                      .map((filter) =>
                                        filter.name === "Weight" ? (
                                          <ProductFilter
                                            key={filter.name}
                                            filter={filter}
                                            showMoreFilters={showMoreFilters}
                                            category={category}
                                            state={state}
                                          />
                                        ) : filter.name === "FitType" ? (
                                          <ProductFilter
                                            key={filter.name}
                                            filter={filter}
                                            showMoreFilters={showMoreFilters}
                                            category={category}
                                            state={state}
                                          />
                                        ) : null
                                      )}
                                </Stack>
                              </Grid>
                              <Grid item xl={2.4}>
                                {filters &&
                                  filters
                                    .filter((filter) =>
                                      ["FinishingType"].includes(filter.name)
                                    )
                                    .map((filter) => (
                                      <>
                                        {filter.name === "FinishingType" && (
                                          <ProductFilter
                                            filter={filter}
                                            key={filter.name}
                                            showMoreFilters={showMoreFilters}
                                            category={category}
                                            state={state}
                                          />
                                        )}
                                      </>
                                    ))}
                              </Grid>
                              <Grid item xl={2.4}>
                                <Stack direction={"column"}>
                                  {filters &&
                                    filters
                                      .filter((filter) =>
                                        ["MfgMethod", "BandEndType"].includes(
                                          filter.name
                                        )
                                      )
                                      .map((filter) => (
                                        <>
                                          {filter.name === "MfgMethod" && (
                                            <ProductFilter
                                              filter={filter}
                                              key={filter.name}
                                              showMoreFilters={showMoreFilters}
                                              category={category}
                                              state={state}
                                            />
                                          )}
                                          {filter.name === "BandEndType" && (
                                            <ProductFilter
                                              filter={filter}
                                              key={filter.name}
                                              showMoreFilters={showMoreFilters}
                                              category={category}
                                              state={state}
                                            />
                                          )}
                                        </>
                                      ))}
                                </Stack>
                              </Grid>
                              <Grid item xl={2.4}>
                                <Stack direction={"column"}>
                                  {filters &&
                                    filters
                                      .filter((filter) =>
                                        ["StonesRowCount", "Drills"].includes(
                                          filter.name
                                        )
                                      )
                                      .map((filter) => (
                                        <>
                                          {filter.name === "StonesRowCount" && (
                                            <ProductFilter
                                              filter={filter}
                                              key={filter.name}
                                              showMoreFilters={showMoreFilters}
                                              category={category}
                                              state={state}
                                            />
                                          )}
                                          {filter.name === "Drills" && (
                                            <ProductFilter
                                              filter={filter}
                                              key={filter.name}
                                              showMoreFilters={showMoreFilters}
                                              category={category}
                                              state={state}
                                            />
                                          )}
                                        </>
                                      ))}
                                </Stack>
                              </Grid>
                              <Grid item xl={2.3}>
                                <Stack direction={"column"}>
                                  {filters &&
                                    filters
                                      .filter((filter) =>
                                        ["AdvancedOptions"].includes(
                                          filter.name
                                        )
                                      )
                                      .map((filter) => (
                                        <>
                                          {filter.name ===
                                            "AdvancedOptions" && (
                                            <ProductFilter
                                              filter={filter}
                                              key={filter.name}
                                              showMoreFilters={showMoreFilters}
                                              category={category}
                                              state={state}
                                            />
                                          )}
                                        </>
                                      ))}
                                </Stack>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider flexview="true" sx={{ width: "100%" }} />
                        </Grid>
                      </>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid container direction={"row"} spacing={1}>
                      {filters &&
                        filters.map(
                          (filter) =>
                            filter.name !== "BestSeller" && (
                              <ProductFilter
                                filter={filter}
                                key={filter.name}
                                showMoreFilters={showMoreFilters}
                                category={category}
                                state={state}
                              />
                            )
                        )}
                    </Grid>
                  </>
                )}
              </>
            )}
          </Stack>
          {!itemsLoaded ? (
            <LoadingIndicator />
          ) : (
            <Grid container spacing={2}>
              {products ? (
                products.map((data, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <ProductTiles data={data} category={category} />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h3" textAlign={"center"}>
                    No Products to List, please try different filters
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}
        </>
      )}
    </ProductLandingContext.Provider>
  );
}
